<template>
  <v-app class="bg-white">
    <v-main class="auth-pages" style="background-color: #000000;">
      
      <div
        class="position-relative "
      >
      <!-- <video autoplay muted loop id="myVideo">
        <source :src="'@/assets/img/iStock-1318771531.mov'" type="video/mov">
        Your browser does not support HTML5 video.
      </video> -->
        <span class="mask bg-gradient-default opacity-8"></span>
              <v-container class="pb-0 position-relative" style=" justify-content: space-around;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);" >
                <div class=" mb-1" style="display: flex; justify-content: space-around" >
    </div>
                <v-row class="align-center d-flex" align="center" justify="center">
                <!-- <v-col lg="5" md="7" class="mx-auto"></v-col> -->
                      <v-col lg="5" md="7" class="mx-auto">
                         
                         <div class="card-padding pb-0" style="text-align:center;" >
                          <img v-if="!company && loadingFlag" :height="this.$vuetify.breakpoint.xs ? 50 : 120" class="image-logo2 mb-5" src="@/assets/img/logo.png">
                          <img v-if="company && loadingFlag &&  company.logo" :height="this.$vuetify.breakpoint.xs ? 150 : 200" class="image-logo2" :src="base_url_img+company.logo">
                          <h2 class="text-h2 font-weight-bolder  mb-2  text-white">Welcome to my Profile!</h2>
                          <v-btn
                            elevation="0"
                            :ripple="false"
                            height="43"
                            class="
                              font-weight-bold
                              text-uppercase
                              btn-primary
                              
                              py-2
                              px-6
                              me-2
                              mt-7
                              mb-2
                              w-100
                            "
                            color="#4AA64E"
                            small
                            v-on:click="checkForm"
                            >Add to contact</v-btn
                          >
                        </div>

                      </v-col>
                    </v-row>
                    </v-container>
                    </div>
      <!-- <div class="header-auth position-relative border-radius-xl min-vh-100">
        <v-container class="py-0">
          <v-row>
            <v-col
              cols="7"
              class="
                d-lg-flex d-none
                h-100
                my-auto
                pe-0
                pa-0
                position-absolute
                top-0
                start-0
                text-center
                justify-center
                flex-column
              "
            >
              <div
                class="
                  position-relative
                  h-100
                  ma-5
                  px-7
                  border-radius-lg
                  d-flex
                  flex-column
                  justify-content-center
                "
                :style="`background-image: url(${require('@/assets/img/Background.jpeg')}); background-size: cover;`"
              ></div>
            </v-col>
            <v-col
              lg="4"
              md="7"
              class="d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5 py-0"
            >
              <fade-transition
                :duration="200"
                origin="center top"
                mode="out-in"
              >
                <v-card class=" bg-transparent">
    <div class="card-padding pb-0">
      <img  :height="this.$vuetify.breakpoint.xs ? 200 : 280" class="image-logo2" src="@/assets/img/Quantum Disputes.png">
      <h2 class="text-h1 font-weight-bolder text-typo mb-2 mt-5">Welcome Back!</h2>
      <p class="mb-0 text-body font-weight-light">
        Please login to your account
      </p>
    </div>
    <div class="card-padding pb-4">
   
      <v-text-field
        label="Email"
        color="#e91e63"
        outlined
        v-model="email"
        prepend-inner-icon="fa-envelope"
        dense
        class="font-size-input input-style py-0"
      ></v-text-field>

      <v-text-field
        label="Password"
        color="#e91e63"
        prepend-inner-icon="fa-key"
        outlined
        v-model="password"
        dense
        :type="showpassword ? 'text' : 'password'"
        :rules="rules"
        :append-icon="showpassword ? 'fa-eye' : 'fa-eye-slash'"
        @click:append="showpassword = !showpassword"
        class="font-size-input input-style py-0"
      ></v-text-field>

      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="
          font-weight-bold
          text-uppercase
          btn-primary
          
          py-2
          px-6
          me-2
          mt-7
          mb-2
          w-100
        "
        color="#4AA64E"
        small
         v-on:click="checkForm"
        >Sign In</v-btn
      >
      
      <div class="text-center">
        <p class="text-sm text-body font-weight-light mt-3 mb-0">
          Don't Have an Account?
          <a
            @click="$router.push('/signup')"
            class="
              text-secondary text-gradient text-decoration-none
              font-weight-bold
            "
            >Sign up</a
          >
        </p>
      </div>
    </div>
  </v-card>
              </fade-transition>
            </v-col>
          </v-row>
        </v-container>
      </div> -->
    </v-main>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import VCard from 'vcard-creator'
import { FadeTransition } from "vue2-transitions";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
    VCard
  },
  data() {
    return {
       checkbox: false,
       showpassword : false,
       email : '',
      password : '',
      showPassword : false,
      dialog: false,
      pageFlag: 'init',
      backgroundLogo: localStorage.getItem('admin_bg'),
      isDark :true,
      buttonFlag   : false,
      loadingLogo : false,
      url : '',
      company : '',
      loadingFlag : false,
      base_url_img : process.env.VUE_APP_WASBI_URL
    };
  },
  beforeCreate: function () {
    
},
created: function () {

      this.getCompanyInfo(this.url)

},
methods: {
gotohome: function () {
    let self = this;
    self.$router.push({ name: "Home" });
},
getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            this.company = response.data.company;
            console.log(this.company);
            this.loadingFlag = true;
            this.loadingLogo = true;
          })

},
checkFormSignup(){
  let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        localStorage.setItem('temp_email',this.email)
        localStorage.setItem('temp_pass',this.password)
        localStorage.setItem('email_verified',0)
        if(this.company)
          this.$router.push('/signup/'+this.url)
        else
          this.$router.push('/signup')
      }
},
forgotPass(){
    this.buttonFlag =true;
    this.isDark = false;
    axios({
          method: "POST",
           
          url: "/api/auth/forgot-password",
          data: {
            email: this.email,
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Forgot Password Mail sent', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.isDark = true;
                this.pageFlag = 'login';
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
},
gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},gotoGoogle(){
      let self = this;
        this.$router.push('/redirect/google');
        location.reload();
  },
  gotoMs(){
      let self = this;
        this.$router.push('/redirect/microsoft');
        location.reload();
  },
  gotoYahoo(){
      let self = this;
        this.$router.push('/redirect/yahoo');
        location.reload();
  },
  exportToFile(card, name='contact', force=true) {
            const a = document.createElement('a');
            const file = new Blob([card], { type: 'text/vcard'});

            a.href = URL.createObjectURL(file);
            a.download = name;
            a.click();

            URL.revokeObjectURL(a.href);
  },
/**
 * checkForm
 * login the user with valid email/pass
 */
checkForm: function(e) {

      let flag = 1;
      let id = this.$route.params.id;
      if (!id) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      
      if (flag == 1) {
        axios({
          method: "POST",
           
          url: "/api/auth/add-contact",
          data: {
            id: id
          }
        })
          .then(response => {
            let user = response.data.data;
            user.instagram = user.instagram  ? "http://instagram.com/"+ user.instagram : ''
            user.facebook = user.facebook ? "http://www.facebook.com/profile.php?id=" + user.facebook : ''
            user.youtube = user.youtube ? "http://www.youtube.com/user/"+ user.youtube : ''
            user.linkedin = user.linkedin ? "http://www.linkedin.com/in/"+ user.linkedin : ''
            user.website = user.instagram ? "http://instagram.com/"+ user.instagram : ''
            user.twitter = user.twitter ? "http://twitter.com/"+ user.twitter : ''
            user.pinterest= user.pinterest ? "http://pinterest.com/"+ user.pinterest : ""
            user.tiktok= user.tiktok ? "http://www.tiktok.com/@"+ user.tiktok : ''
            user.snapchat=  user.snapchat ? "http://snapchat.com/add/"+ user.snapchat : ''
            user.reddit= user.reddit ? "http://www.reddit.com/user/"+ user.reddit : ''
            user.tumblr= user.tumblr ? "http://tumblr.com/"+ user.tumblr : ''
            user.flickr= user.flickr ? "http://www.flickr.com/photos/"+ user.flickr : ''
            user.vine= user.vine ? "http://vine.co/"+ user.vine : ''
            user.medium= user.medium ? "http://medium.com/@"+ user.medium : ""
            user.soundcloud= user.soundcloud ? "http://soundcloud.com/"+ user.soundcloud : ''
            user.twitch= user.twitch ? "http://www.twitch.tv/"+ user.twitch  : ""
            user.clubhouse= user.clubhouse ? "https://www.joinclubhouse.com/@"+ user.clubhouse : ""
            const myVCard = new VCard()
            myVCard.addName(user.last_name, user.first_name)
            myVCard.addCompany(user.business_name)
            myVCard.addEmail(user.email)
            myVCard.addPhoneNumber(user.phone)
            if(response.data.data.instagram)
            myVCard.addSocial(user.instagram, 'Instagram')
            if(response.data.data.facebook)
              myVCard.addSocial(user.facebook, 'Facebook')
            if(response.data.data.linkedin)
              myVCard.addSocial(user.linkedin, 'LinkedIn')
            if(response.data.data.youtube)
              myVCard.addSocial(user.youtube, 'Youtube')
            if(response.data.data.twitter)
              myVCard.addSocial(user.twitter, 'Twitter')
            if(response.data.data.pinterest)
              myVCard.addSocial(user.pinterest, 'Pinterest')
            if(response.data.data.tiktok)
              myVCard.addSocial(user.tiktok, 'Tiktok')
            if(response.data.data.snapchat)
              myVCard.addSocial(user.snapchat, 'Snapchat')
            if(response.data.data.reddit)
              myVCard.addSocial(user.reddit, 'Reddit')
            if(response.data.data.tumblr)
              myVCard.addSocial(user.tumblr, 'Tumblr')
            if(response.data.data.flickr)
              myVCard.addSocial(user.flickr, 'Flickr')
            if(response.data.data.vine)
              myVCard.addSocial(user.vine, 'Vine')
            if(response.data.data.medium)
              myVCard.addSocial(user.medium, 'Medium')
            if(response.data.data.soundcloud)
              myVCard.addSocial(user.soundcloud, 'Soundcloud')
            if(response.data.data.clubhouse)
              myVCard.addSocial(user.clubhouse, 'Clubhouse')

            .addAddress(user.address)
            .addURL(user.website)
            
            const output = myVCard.toString()
            console.log(output);
            const preElement = document.querySelector('#output')
            const outputNode = document.createTextNode(output)
            this.exportToFile(output,'contact',true)
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }
    }
  }
    
};
</script>
<style>
  .check-login .v-label {
    color: white;
  }
  .check-login .v-icon {
    color: white;
  }
.input-login .v-icon{
    color: #FDE038!important;
}
.input-login .v-label{
  color: #FDE038!important;
}
.input-login #input-6{
  color: #FDE038!important;
}
.input-login #input-10{
  color: #FDE038!important;
}
</style>