<template>
  <v-app id="inspire">
  
  <vue-header></vue-header>
  <v-main style="padding:0px!important;">
      <v-container style="margin-top:200px;margin-bottom: 72px;">
          <v-row
              align="center"
              style="margin-bottom: 80px!important;"
              justify="center"
            >
              <v-col cols="12" md="12">
                <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                  <v-card-text style="margin-bottom: -30px;">
                      <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                      <h5 class="text-h4 font-weight-bold text-typo">My VCard <span v-if="company.id">({{ company.name }})</span></h5>
                      <h5 class="text-h5 text-typo" v-if="company.id">Card ID: <span v-if="company.id">{{ company.id }}</span></h5>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      ></v-col>
                              </v-row>
                      <v-row class="mt-2">
                        <v-col sm="6" cols="12" class="py-0">
                          <v-select
                                label="Color Scheme"
                                outlined
                                dense
                                class="font-size-input input-style"
                                v-model="company.color"
                                :items="['Orange','Green','Purple','Red','Pink','Blue','Dark Blue','Black','Silver','Hot Pink','Flat Black']"
                                ></v-select>
                        </v-col>
                        <v-col sm="6" cols="12" class="py-0">
                          <v-select
                                label="Text Color"
                                outlined
                                dense
                                class="font-size-input input-style"
                                v-model="company.header_color"
                                :items="['Light Text','Dark Text',]"
                                ></v-select>
                        </v-col>

                      </v-row>
                      <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                              color="#e91e63"
                              label="First Name*"
                              v-model="user.first_name"
                              class="font-size-input input-style"
                            >
                            </v-text-field>
                          </v-col>
                          
                          <v-col cols="12" md="6">
                            <v-text-field
                              color="#e91e63"
                              label="Last Name*"
                              v-model="user.last_name"
                              class="font-size-input input-style"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" class="py-0" md="6">
                            <v-text-field
                              color="#e91e63"
                              label="Email*"
                              disabled
                              v-model="user.email"
                              class="font-size-input input-style"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0" md="6">
                            <v-text-field
                              color="#e91e63"
                              v-model="user.phone_m"
                              v-mask="'(###) ###-####'"
                              label="Phone*"
                              class="font-size-input input-style"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              color="#e91e63"
                              label="Business Name"
                              v-model="user.business_name"
                              class="font-size-input input-style"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              color="#e91e63"
                              label="Business Address"
                              v-model="user.business_address"
                              class="font-size-input input-style"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              color="#e91e63"
                              label="Instagram"
                              v-model="user.instagram"
                              class="font-size-input input-style"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              color="#e91e63"
                              label="Facebook"
                              v-model="user.facebook"
                              class="font-size-input input-style"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              color="#e91e63"
                              label="Tiktok"
                              v-model="user.linkedin"
                              class="font-size-input input-style"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              color="#e91e63"
                              label="Youtube Channel"
                              v-model="user.youtube"
                              class="font-size-input input-style"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              color="#e91e63"
                              label="Website"
                              v-model="user.website"
                              class="font-size-input input-style"
                            >
                            </v-text-field> 
                          </v-col>
                            <v-col cols="12"  sm="6" md="6" lg="6"  >
                                      <p for="my-file">Profile Picture</p>
                                        <v-btn
                                                type="button"
                                                color="blue"
                                                small
                                                :loading="loadingUpload"
                                                class="
                                                  font-weight-bolder
                                                  btn-default
                                                  mb-5
                                                  px-8
                                                  ms-auto
                                                "
                                                @click="changeTrigger = true"
                                              >
                                                Select profile picture
                                              </v-btn>
                                          <avatar-cropper
                                            :upload-handler="handleUploaded"
                                            :output-mime="cropperOutputMime"
                                            v-model="changeTrigger"
                                            />

                                        <div class="border p-2 mt-3">
                                        <p>Preview Here:</p>
                                        <template >
                                          <v-img contain v-if="!preview" style="width:300px;" src="@/assets/img/avatar.png" class="img-fluid" />
                                          <v-img contain v-else :src="preview" class="img-fluid" />
                                        </template>
                                      </div>
                                        </v-col>
                        </v-row>
                       <v-row class="mt-2">
                      <v-col
                      cols="12"
                      md="12"
                      >
  
                   <v-card-actions class="justify-center ">
                    <v-row>
                      <v-col>
                        <v-btn
                          color="#4CAF50"
                          large
                          :loading="loading"
                          @click="saveCard"
                          class="
                          float-right
                            font-weight-bolder
                            btn-default
                            mb-5
                            px-8
                            ms-auto
                          "
                          
                        >
                          Save
                        </v-btn>
                        </v-col>
                      </v-row>
                  </v-card-actions>
                      </v-col>
  
                      
                    </v-row>
                  </v-card-text>
                  <!-- <v-card-actions class="justify-center ">
                    <v-btn
                      tile  dark
                      style="height: 49px;width:95px;"
                      color="primary"
                      class="mb-10"
                      @click="saveProfile"
                    >Save</v-btn>
                  </v-card-actions> -->
                </v-card>
              </v-col>
            </v-row>
      </v-container>
      
       <vue-footer></vue-footer>
       </v-main>
  </v-app>
  
  </template>
  
  <script>
  import { BIconShield } from 'bootstrap-vue';
import AvatarCropper from 'vue-avatar-cropper'
  import { VueTelInput } from 'vue-tel-input'
  export default {
  components: {
        VueTelInput,AvatarCropper
    },
    data () {
  
        return {
          fname : '',
          lname : '',
          email : '',
          name:'',
          preview : '',
          user: '',
          cropperOutputMime : '',
          preview1 : '',
          cropperOutputMime1 : '',
          panel : 0,
          changeTrigger : false,
          changeTrigger1 : false,
          preview2: '',
          cropperOutputMime2 : '',
          changeTrigger2 : false,
          panel1 : 0,
          phone:'',
          rules: [
                  value => !!value || 'Required.',
              ],
          loading : false,
          password : '',
          is_admin:0,
          confpassword : '',
          role : '',
          companyname : '',
          sector : '',
          no_of_employee : '',
          showPassword : false,
          phoneFlag:0,
          base_url: window.location.origin,
          emailFlag:0,
          country :'',
          otp_email:'',
          otp_phone:'',
          stateOptions: [
                  { name: 'ALABAMA', abbreviation: 'AL'},
                  { name: 'ALASKA', abbreviation: 'AK'},
                  { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                  { name: 'ARIZONA', abbreviation: 'AZ'},
                  { name: 'ARKANSAS', abbreviation: 'AR'},
                  { name: 'CALIFORNIA', abbreviation: 'CA'},
                  { name: 'COLORADO', abbreviation: 'CO'},
                  { name: 'CONNECTICUT', abbreviation: 'CT'},
                  { name: 'DELAWARE', abbreviation: 'DE'},
                  { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                  { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                  { name: 'FLORIDA', abbreviation: 'FL'},
                  { name: 'GEORGIA', abbreviation: 'GA'},
                  { name: 'GUAM', abbreviation: 'GU'},
                  { name: 'HAWAII', abbreviation: 'HI'},
                  { name: 'IDAHO', abbreviation: 'ID'},
                  { name: 'ILLINOIS', abbreviation: 'IL'},
                  { name: 'INDIANA', abbreviation: 'IN'},
                  { name: 'IOWA', abbreviation: 'IA'},
                  { name: 'KANSAS', abbreviation: 'KS'},
                  { name: 'KENTUCKY', abbreviation: 'KY'},
                  { name: 'LOUISIANA', abbreviation: 'LA'},
                  { name: 'MAINE', abbreviation: 'ME'},
                  { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                  { name: 'MARYLAND', abbreviation: 'MD'},
                  { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                  { name: 'MICHIGAN', abbreviation: 'MI'},
                  { name: 'MINNESOTA', abbreviation: 'MN'},
                  { name: 'MISSISSIPPI', abbreviation: 'MS'},
                  { name: 'MISSOURI', abbreviation: 'MO'},
                  { name: 'MONTANA', abbreviation: 'MT'},
                  { name: 'NEBRASKA', abbreviation: 'NE'},
                  { name: 'NEVADA', abbreviation: 'NV'},
                  { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                  { name: 'NEW JERSEY', abbreviation: 'NJ'},
                  { name: 'NEW MEXICO', abbreviation: 'NM'},
                  { name: 'NEW YORK', abbreviation: 'NY'},
                  { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                  { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                  { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                  { name: 'OHIO', abbreviation: 'OH'},
                  { name: 'OKLAHOMA', abbreviation: 'OK'},
                  { name: 'OREGON', abbreviation: 'OR'},
                  { name: 'PALAU', abbreviation: 'PW'},
                  { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                  { name: 'PUERTO RICO', abbreviation: 'PR'},
                  { name: 'RHODE ISLAND', abbreviation: 'RI'},
                  { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                  { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                  { name: 'TENNESSEE', abbreviation: 'TN'},
                  { name: 'TEXAS', abbreviation: 'TX'},
                  { name: 'UTAH', abbreviation: 'UT'},
                  { name: 'VERMONT', abbreviation: 'VT'},
                  { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                  { name: 'VIRGINIA', abbreviation: 'VA'},
                  { name: 'WASHINGTON', abbreviation: 'WA'},
                  { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                  { name: 'WISCONSIN', abbreviation: 'WI'},
                  { name: 'WYOMING', abbreviation: 'WY' }
              ],
          company : {
                  id: '',
                  name : "",
                  business_name : '',
                  position : '',
                  color : '',
                  photo : [],
                  card_url : '',
                  background : [],
                  photo_height :250,
                  photo_width :250,
                  qr_image :[],
                  header_color : 'Light Text',
                  qr_code_button : 'Scan my QR Code',
                  save_button :'Add to contacts',
                  share_button : 'Share my card',
                  add_to_homecreeen : 'Add to home screen',
                  own_card : "Start your 7-day free trial",
                  links: [{
                    id: 0,
                    type : 'Phone',
                    label : 'Phone',
                    link : 'tel:',
                    value : ''
                  }],
  
  
          },
          otp_phone_input:"",
          otp_email_input:"",
          video_file : '',
          user_phone:'',
          user_email:'',
          status:0,
  
          tab: null,
          headers: [
  
            { text: '', value: 'CompanyURL' },
            { text: '', value: 'CompanyName' },
  
          ],
          history: [
  
          ],
  
          setting: [
          ],
  
          items: [
                'Admin',
                'Viewer',
        ],
        select_role : 'Admin',
         sectors: [
  
        ],
        employeeNumber: [
                '10-50',
                '50-100',
                'More than 100',
  
        ],
  
        }
      },
    watch: {
              company: {
                  handler () {
                      // this.company.company_url = this.company.name.replace(/\s/g, '-').toLowerCase()
                  },
                  deep: true,
              },
          },
      methods: {
      addPhone(){
          this.phoneFlag = 1;
      },
      addEmail(){
          this.emailFlag = 1;
      },
      handleUploaded(cropper) {
        let self = this;
            let image = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime);
            let img = this.dataURLtoFile(image,'asdasd.jpg');
            self.preview = image;
            self.company.photo = img
            
  
          },
          handleUploaded1(cropper) {
        let self = this;
            let image = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime1);
            let img = this.dataURLtoFile(image,'asdasd.jpg');
            self.preview1 = image;
            self.company.background = img
            
  
          },
          ImageUploaded() {
            let self = this;
            let data  = new FormData();
            data.append('file', self.company.photo);
            data.append('client_id', localStorage.getItem('user_id'));
            this.loadingUpload = true;
            axios({
              method: "POST",
              url: "/api/upload-avatar",
              headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
              data: data
              })
              .then(response => {
              Vue.$toast.success("Profile Picture uploaded", {
                  position: 'top-right'
                  });
                  self.loading = false;
                  self.loadingUpload = false;

              })
              .catch(err => {
                  Vue.$toast.error("Invalid file to upload", {
                  position: 'top-right'
                  });
                  this.isDisableUpload = false;
                  this.isDarkUpload = true;
              });

        },
    dataURLtoFile(dataurl, filename) {
   
   var arr = dataurl.split(','),
       mime = arr[0].match(/:(.*?);/)[1],
       bstr = atob(arr[1]), 
       n = bstr.length, 
       u8arr = new Uint8Array(n);
       
   while(n--){
       u8arr[n] = bstr.charCodeAt(n);
   }
   
   return new File([u8arr], filename, {type:mime});
  },
      add () {
          this.company.links.push({
                id :0,
                type : 'Phone',
                label : 'Phone',
                link : 'tel:',
                value : ''
              })
              console.log(this.company.links);
        },
        
        remove (index) {
            this.company.links.splice(index, 1)
        },
      countryChanged(country) {
          this.country = '+' + country.dialCode
      },
      populateText(item){
        let oldLink = this.renderInputFieldByType(item.type)
        console.log(oldLink);
        item.link = oldLink+item.value
      },
      verify(){
      let self = this;
      let flag = 1;
      if (!self.email && self.emailFlag ==1) {
      Vue.$toast.error('Email '+this.$t('questionnaire.questionnaire_alert_required'), {
          position: 'top-right'
      });
      this.$refs['email'].focus()
      flag = 0;
      }
      let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (reg.test(self.email) == false && flag == 1 && self.emailFlag == 1) {
          Vue.$toast.error('Invalid Email format', {
          position: 'top-right'
          });
          this.$refs['email'].focus()
          flag = 0;
      }
      if (!self.phone && flag == 1 && self.phoneFlag == 1) {
      Vue.$toast.error('Phone Number '+this.$t('questionnaire.questionnaire_alert_required'), {
          position: 'top-right'
      });
      this.$refs['phone'].focus()
      flag = 0;
      }
  
      if (flag == 1) {
      var email = this.email;
      axios({
          method: "POST",
           
          url: "/api/auth/send_otp",
          data: {
          flag : self.phoneFlag == 1 ? 'phone' : 'email',
          email: self.email,
          phone:  self.phone,
          code: self.country,
          logged_flag : 1
          }
      })
          .then(response => {
          let data = response.data;
          if (data.data.flag == 1) {
              if(self.phoneFlag == 1 ){
                  self.otp_phone = data.data.otp;
                  self.phoneFlag = 2;
              }
              else{
                  self.otp_email = data.data.otp;
                  self.emailFlag = 2;
              }
              Vue.$toast.success(data.message, {
                  position: 'top-right'
              });
  
          } else {
                  Vue.$toast.error(data.message, {
                  position: 'top-right'
                  });
              }
          })
          .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
          });
        }
      },
      getParentDomain() {
        const hostname = window.location.hostname;
        const domainParts = hostname.split('.');
        
        // Assuming the domain is of the form subdomain.example.com
        if (domainParts.length >= 2) {
            const parentDomain = domainParts.slice(-2).join('.');
            return parentDomain;
        } else {
            return hostname; // For cases like localhost or single part domains
        }
    },
      gotoLink(vcard,edit = false){
      let id = vcard.domain
      let route = '';
      let url = 'http://'+id+'.'+this.getParentDomain();
        // if(vcard.plan == 'Pro'){
          // route = this.$router.resolve({path: '/card/'+id});
          window.open(url, '_blank');
        // }else{
        //   route = url+'card/'+id;
        //   window.open(route, '_blank');
        // }
    },
      verifyOTP(){
          if(this.phoneFlag ==2){
              if(! this.otp_phone_input){
                  Vue.$toast.error('OTP '+this.$t('questionnaire.questionnaire_alert_required'), {
                      position: 'top-right'
                  });
              }else{
                  if(this.otp_phone == this.otp_phone_input){
                      axios({
                          method: "POST",
                           
                          url: "/api/updateprofile",
                          data: {
                              flag: 'phone',
                              phone: this.phone,
                              code: this.country
                          }
                          })
                          .then(response => {
                              let data = response.data;
                              if (data.data == 1) {
                                  Vue.$toast.success(data.message, {
                                      position: 'top-right'
                                  })
                                  this.getUserProfile()
                                  this.phoneFlag = 3;
                              } else {
                                  Vue.$toast.error(data.message, {
                                  position: 'top-right'
                                  });
                              }
                          })
                          .catch(err => {
                              Vue.$toast.error(err, {
                              position: 'top-right'
                              });
                          });
  
  
                  }else{
                      Vue.$toast.error('Invalid OTP', {
                          position: 'top-right'
                      });
                      this.otp_phone_input ="";
                      this.$refs['phone_otp'].focus()
                  }
              }
  
          }else{
              if(! this.otp_email_input){
                  Vue.$toast.error('OTP '+this.$t('questionnaire.questionnaire_alert_required'), {
                      position: 'top-right'
                  });
              }else{
                  if(this.otp_email == this.otp_email_input){
                       axios({
                          method: "POST",
                           
                          url: "/api/updateprofile",
                          data: {
                              flag: 'email',
                              email: this.email,
                          }
                          })
                          .then(response => {
                              let data = response.data;
                              if (data.data == 1) {
                                  Vue.$toast.success(data.message, {
                                      position: 'top-right'
                                  })
                                  //this.$router.push("/home");
                                  this.emailFlag = 3;
                                  this.getUserProfile()
                              } else {
                                  Vue.$toast.error(data.message, {
                                  position: 'top-right'
                                  });
                              }
                          })
                          .catch(err => {
                              Vue.$toast.error(err, {
                              position: 'top-right'
                              });
                          });
  
                  } else{
                      Vue.$toast.error('Invalid OTP', {
                          position: 'top-right'
                      });
                      this.otp_email_input ="";
                      this.$refs['email_otp'].focus()
                  }
              }
          }
      },
      renderInputField(index){
        let label = '';
        let link = '';
        switch (this.company.links[index].type) {
          case 'Phone':
              label = 'Phone';
              link = 'tel:';
            break;
            case 'Whatsapp':
              label = 'Whatsapp';
              link = 'https://wa.me/';
            break;
            case 'Email':
              label = 'Email';
              link = 'mailto:';
            break;
            case 'SMS/Text':
              label = 'SMS/Text';
              link = 'sms:';
            break;
            case 'Website':
              label = 'Website';
              link = 'https://';
            break;
            case 'Zoom':
              label = 'Zoom';
              link = '';
            break;
            case 'Event':
              label = 'Event';
              link = '';
            break;
            case 'Instagram':
              label = 'Instagram';
              link = 'https://www.instagram.com/';
            break;
            case 'Snapchat':
              label = 'Snapchat';
              link = 'https://www.snapchat.com/add/';
            break;
            case 'Twitter':
              label = 'Twitter';
              link = 'https://www.twitter.com/';
            break;
            case 'Facebook':
              label = 'Facebook';
              link = 'https://www.facebook.com/';
            break;
            case 'LinkedIN':
              label = 'LinkedIN';
              link = 'https://www.linkedin.com/in/';
            break;
            case 'Clubhouse':
              label = 'Clubhouse';
              link = 'https://www.clubhouse.com/';
            break;
            case 'Cash App':
              label = 'Cash App';
              link = 'https://cash.app/';
            break;
            case 'Venmo':
              label = 'Venmo';
              link = 'https://venmo.com/';
            break;
            case 'Zelle Transfer':
              label = 'Zelle Transfer';
              link = '';
            break;
            case 'Peymynt':
              label = 'Peymynt';
              link = '';
            break;
            case 'Paypal':
              label = 'Paypal';
              link = '';
            break;
            case 'Stripe':
              label = 'Stripe';
              link = '';
            break;
          default:
            break;
        }
        this.company.links[index].label = label
        this.company.links[index].link = link
      },
      renderInputFieldByType(type){
        let label = '';
        let link = '';
        switch (type) {
          case 'Phone':
              label = 'Phone';
              link = 'tel:';
            break;
            case 'Whatsapp':
              label = 'Whatsapp';
              link = 'https://wa.me/';
            break;
            case 'Email':
              label = 'Email';
              link = 'mailto:';
            break;
            case 'SMS/Text':
              label = 'SMS/Text';
              link = 'sms:';
            break;
            case 'Website':
              label = 'Website';
              link = 'https://';
            break;
            case 'Zoom':
              label = 'Zoom';
              link = '';
            break;
            case 'Event':
              label = 'Event';
              link = '';
            break;
            case 'Instagram':
              label = 'Instagram';
              link = 'https://www.instagram.com/';
            break;
            case 'Snapchat':
              label = 'Snapchat';
              link = 'https://www.snapchat.com/add/';
            break;
            case 'Twitter':
              label = 'Twitter';
              link = 'https://www.twitter.com/';
            break;
            case 'Facebook':
              label = 'Facebook';
              link = 'https://www.facebook.com/';
            break;
            case 'LinkedIN':
              label = 'LinkedIN';
              link = 'https://www.linkedin.com/in/';
            break;
            case 'Clubhouse':
              label = 'Clubhouse';
              link = 'https://www.clubhouse.com/';
            break;
            case 'Cash App':
              label = 'Cash App';
              link = 'https://cash.app/';
            break;
            case 'Venmo':
              label = 'Venmo';
              link = 'https://venmo.com/';
            break;
            case 'Zelle Transfer':
              label = 'Zelle Transfer';
              link = '';
            break;
            case 'Peymynt':
              label = 'Peymynt';
              link = '';
            break;
            case 'Paypal':
              label = 'Paypal';
              link = '';
            break;
            case 'Stripe':
              label = 'Stripe';
              link = '';
            break;
          default:
            break;
        }
        return link;
      },
      handleUploaded2(cropper) {
      let self = this;
          let image = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime2);
          let img = this.dataURLtoFile(image,'asdasd.jpg');
          self.preview2 = image;
          self.company.qr_image = img
          

        },
      getAddressData: function (addressData, placeResultData, id) {
              this.company.address = addressData.name;
              this.company.city = addressData.locality;
              this.company.state = addressData.administrative_area_level_1;
              this.company.zip = addressData.postal_code
          },
        changePassword: function () {
            let flag = 1;
            if (!this.password) {
              Vue.$toast.error('Password '+this.$t('questionnaire.questionnaire_alert_required'), {
                  position: 'top-right'
              });
              this.$refs['password'].focus()
              flag = 0;
            }
  
            if (!this.confpassword) {
              Vue.$toast.error('Confirm Password '+this.$t('questionnaire.questionnaire_alert_required'), {
                  position: 'top-right'
              });
              this.$refs['confpassword'].focus()
              flag = 0;
            }
            if (this.confpassword != this.password && flag == 1) {
              Vue.$toast.error('Confirm password did not match', {
                  position: 'top-right'
              });
              self.$refs['conf_password'].focus()
              flag = 0;
          }
  
            if (flag == 1) {
  
              axios({
              method: "POST",
               
              url: "/api/passwordchange",
              data: {
              password: this.password,
              password_confirmation: this.confpassword,
            }
          })
            .then(response => {
              let data = response.data;
              if (data.data == 1) {
                Vue.$toast.success(this.$t('my_profile.profile_alert_password_change_successfull'), {
                  position: 'top-right'
                  });
  
              } else {
                  Vue.$toast.error(data.message, {
                  position: 'top-right'
                  });
              }
            })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            });
        } else {
  
        }
  
      },
      
    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.user.first_name) {
            Vue.$toast.error('First Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.last_name && flag == 1) {
            Vue.$toast.error('Last Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.phone_m && flag == 1) {
            Vue.$toast.error('phone is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        if (!this.user.email && flag == 1) {
            Vue.$toast.error('Email is required', {
                position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }
        
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(this.user.email) == false && flag == 1 ) {
            Vue.$toast.error('Invalid Email format', {
            position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }

        if (flag == 1) {
          this.loading = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/updateprofile",
          data: self.user
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Profile updated', {
                    position: 'top-right'
                  })

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
            // this.loading = false;
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },
  
      getUserProfile() {
      let flag = 1;
      let url =process.env.VUE_APP_WASBI_URL
      let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
        axios
          .get("/api/user",header)
          .then(response => {
            if(response.data.vcard.length > 0) {
              let data = response.data.vcard[0];
              data.links = JSON.parse(JSON.parse(data.items))
             this.company = data;
             this.user = response.data.user;
             this.preview = response.data.user.avatar ? url+response.data.user.avatar : ''
            //  if(this.company.photo){
            //   this.preview =  data.api_ref_id && data.api_ref_id != 'null'  ? this.company.photo : url+'vcard/'+this.company.photo
            //   this.company.photo = []
            //  }
            //  if(this.company.background){
            //   this.preview1 = data.api_ref_id  && data.api_ref_id != 'null' ? this.company.background : url+'vcard/'+this.company.background
            //   this.company.background = []
            //   }
            //   if(this.company.qr_image){
            //     this.preview2 = data.api_ref_id  && data.api_ref_id != 'null' ? this.company.qr_image :url+'vcard/'+this.company.qr_image
            //     this.company.qr_image = []
            //   }
            //   if(this.company.video_background){
            //     this.company.video_background = data.video_background  && data.video_background != 'null' ? this.company.video_background : ''
            //   }
            }
              
           })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
  
       },
  
       removePhoto(id) {
      let flag = 1;
      let self = this;
      if(confirm("Are you sure you want to remove?")){
      let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
        axios
          .post("/api/remove-card-photo/"+this.company.id,{type : id},header)
          .then(response => {
              let data = response.data;
              self.saveProfile();
              if(id == 'background')
                self.preview1 = ''
              if(id == 'photo')
                self.preview = ''
              if(id == 'qr')
                self.preview2 = ''
              // location.reload();
              
           })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
            }
  
       },
  
  
      saveCard: function(e) {
        let self = this;
        let flag = 1;
        let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
          if (!this.company.name) {
              Vue.$toast.error('Name is required', {
                  position: 'top-right'
              });
              flag = 0;
          }
          // if (!this.company.card_url && flag == 1) {
          //     Vue.$toast.error('URL is required', {
          //         position: 'top-right'
          //     });
          //     flag = 0;
          // }
          if (!this.company.color && flag == 1) {
              Vue.$toast.error('Color Scheme is required', {
                  position: 'top-right'
              });
              flag = 0;
          
            }
      
         
          if (flag == 1) {
            let form  = new FormData();
            for (var key in self.company) {
              if(key != 'links')
                form.append(key,self.company[key]);
            }
            
            form.append("client_id",localStorage.getItem('user_id'));
            self.loading = true;
          axios({
            method: "POST",
           headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
             
            url: "/api/edit-card",
            data: form
          })
            .then(response => {
              let data = response.data;
              if (response.data.flag == 1) {
                  self.ImageUploaded()
                  self.saveProfile()
                    // self.getUserProfile();
                    // self.loading = false;
  
              } else {
                  Vue.$toast.error('URL already exist', {
                  position: 'top-right'
                  });
                  self.loading = false;
              }
            })
            .catch(err => {
              console.log(err.response);
              
                if(err.response.data.errors.email)
                  Vue.$toast.error(err.response.data.errors.email[0], {
                  position: 'top-right'
                  });
                if(err.response.data.errors.user_name)
                  Vue.$toast.error(err.response.data.errors.user_name[0], {
                  position: 'top-right'
                  });     
                if(err.response.data.errors.company_url)
                  Vue.$toast.error(err.response.data.errors.company_url[0], {
                  position: 'top-right'
                  });             
            });
          }
      },
  
   },
  
  created() {
        console.log('created called.');
          this.getUserProfile();
      },
  
  }
  </script>
  <style>
  .button-label .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
    color: black !important;
  }
  </style>
  
  