<template>
    <v-app class="bg-white">
    <v-main class="auth-pages"  >
        <v-col cols="12" style="display: flex; justify-content: space-around;margin-top: 10px;" >
                  <img :height="this.$vuetify.breakpoint.xs ? 200 : 200" class="image-logo2" src="@/assets/img/logo.png">
            </v-col>
    <div class="terms-conditions">
      <h1>Terms & Conditions</h1>
      <p>Welcome to the AutoSync website. By accessing and using our website, you agree to comply with these terms and conditions. Please read them carefully before using our services.</p>
  
      <h2>1. Acceptance of Terms</h2>
      <p>By accessing our website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions, as well as any additional guidelines and future modifications. If you do not agree with any part of these terms, please refrain from using our website.</p>
  
      <h2>2. Intellectual Property</h2>
      <p>All content on the AutoSync website, including text, graphics, logos, images, videos, and software, is the property of AutoSync and is protected by intellectual property laws. You may not use, reproduce, distribute, modify, or create derivative works without our prior written consent.</p>
  
      <h2>3. Use of Services</h2>
      <p>Our website provides information and services related to automotive data synchronization and management. You agree to use our services for lawful purposes only and not to engage in any activity that may disrupt or interfere with the proper functioning of our website.</p>
  
      <h2>4. Privacy Policy</h2>
      <p>We take the privacy of our users seriously. Our Privacy Policy outlines how we collect, use, and protect your personal information. By using our website, you consent to the collection and use of your information as described in our Privacy Policy.</p>
  
      <h2>5. Third-Party Links</h2>
      <p>Our website may contain links to third-party websites or resources. These links are provided for your convenience, and we do not endorse or assume any responsibility for the content, policies, or practices of these third-party websites. You access them at your own risk.</p>
  
      <h2>6. Disclaimer of Warranties</h2>
      <p>We strive to provide accurate and up-to-date information on our website. However, we make no warranties or representations regarding the accuracy, reliability, or completeness of the content. Your use of the website is at your own risk.</p>
  
      <h2>7. Limitation of Liability</h2>
      <p>In no event shall AutoSync or its affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our website or services. This includes, but is not limited to, any loss of profits, data, or business interruption.</p>
  
      <h2>8. Modifications and Termination</h2>
      <p>We reserve the right to modify, suspend, or terminate our website or services at any time without prior notice. We may also update these terms and conditions from time to time. Your continued use of the website after any modifications constitutes your acceptance of the revised terms.</p>
  
      <h2>9. Governing Law</h2>
      <p>These terms and conditions shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].</p>
  
      <h2>10. Contact Us</h2>
      <p>If you have any questions or concerns regarding these terms and conditions, please contact us at <a href="mailto:support@autosync.vip">support@autosync.vip</a>.</p>
  
      <p>By using the AutoSync website, you agree to abide by these terms and conditions. If you do not agree with any part of these terms, please refrain from using our website.</p>
    </div>
    </v-main>
    </v-app>
  </template>
  
  <script>
  export default {
    name: 'TermsAndConditions',
  };
  </script>
  
  <style scoped>
  .terms-conditions {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .terms-conditions h1, .terms-conditions h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .terms-conditions p {
    margin-bottom: 20px;
    line-height: 1.6;
    color: #555;
  }
  
  .terms-conditions a {
    color: #1a73e8;
    text-decoration: none;
  }
  
  .terms-conditions a:hover {
    text-decoration: underline;
  }
  </style>
  