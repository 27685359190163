<template>
 <v-app id="inspire" >
    <v-main class="auth-pages">
    <div
        class="position-relative min-vh-100"
      
      >
              <v-container class="pb-0 position-relative" >
    <div >
        <v-row class="pl-5 pr-5" >
        <v-col cols="12" align="center" justify="center" >
                <img v-if="!company_info" @click="$router.push('/')" :height="this.$vuetify.breakpoint.xs ? 80 : 200" class="image-logo2" src="@/assets/img/logo.png">
                <img v-if="company_info && company_info.logo" @click="$router.push('/')" :height="this.$vuetify.breakpoint.xs ? 80 : 300" class="image-logo2" :src="base_url_img+company_info.logo">
        </v-col>
        </v-row>
        <v-row align="center" justify="center" class="">
        <v-col cols="12"  sm="11" md="8" lg="8" xs="10">
            <h1 class="text-h4 text-typo " style="" >APPLY ONLINE</h1>
        </v-col>
        <v-col cols="12"  sm="11" md="8" lg="8" xs="10">
            <v-card>

                    <v-tabs
                    v-model="tabs"
                    
                    color="green"
                    >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab
                        href="#mobile-tabs-5-1"
                        class="primary--text"
                    >
                        Buyer
                    </v-tab>

                    <v-tab
                        href="#mobile-tabs-5-2"
                        class="primary--text"
                    >
                        Co-Buyer
                    </v-tab>
                    </v-tabs>


                <v-tabs-items v-model="tabs">
                <v-tab-item
                    :key="1"
                    :value="'mobile-tabs-5-1'"
                >
                    <v-card flat>
                    <v-card-text >
                        <v-form
                                ref="form_buyer"
                                v-model="valid"
                                lazy-validation
                            >
                        <v-row>
                           
                            <v-col cols="12"  md="12" lg="12" >
                                <p >All fields marked with an asterisk (*) are required</p>
                                <p class="text-h6">Personal Information</p>
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;">
                                <span >First Name<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="company.first_name"
                                    />
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;">
                                <span >Last Name<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    outlined
                                    :rules="rules_name"
                                    ref="name"
                                    v-model="company.last_name"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Email<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    outlined
                                    :rules="emailRules"
                                    ref="name"
                                    v-model="company.email"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Cell Phone<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    append-icon="fa-mobile"

                                    class="font-size-input input-style"
                                    width="300px"
                                    outlined
                                     v-mask="'(###) ###-####'"
                                    :rules="rules_name"
                                    ref="name"
                                    v-model="company.phone_m"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Home Phone (Optional)</span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    append-icon="fa-phone"
                                    class="font-size-input input-style"
                                    width="300px"
                                     v-mask="'(###) ###-####'"
                                    outlined
                                    ref="name"
                                    v-model="company.phone_h"
                                    />
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;">
                                <span >Date of Birth<span class="required_span"> *</span></span>
                                <v-text-field
                                v-model="dob"
                                width="300px"
                                outlined
                                placeholder="MM/DD/YYYY"
                                v-mask="'##/##/####'"
                                ref="dob"
                                class="font-size-input input-style"
                                :suffix="'Age '+ age"
                                :rules="rules_name"
                            >
                            
                            </v-text-field>
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;">
                                <span >SSN<span class="required_span"> *</span></span>
                                <v-text-field
                                :rules="rules_name"
                                outlined
                                v-mask="'###-##-####'"
                                v-model="company.ssn"
                                required
                                class="font-size-input input-style"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;">
                                <span >Driver's License Number</span>
                                <v-text-field
                                outlined
                                v-model="company.driving_license_number"
                                required
                                class="font-size-input input-style"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;">
                                <span >Driver's License State</span>
                             
                                <v-select
                                    :items="stateOptions"
                                    item-text="name"
                                    item-value="abbreviation"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Select State"
                                    v-model="company.driving_license_state"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;">
                                <span >Driver's License Issue Date</span>
                                
                                <v-menu
                                    ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :return-value.sync="company.driving_license_issue_date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="company.driving_license_issue_date"
                                        append-icon="fa-calendar"
                                        readonly
                                        v-bind="attrs"
                                        class="font-size-input input-style"
                                        outlined
                                        clearable
                                        v-on="on"
                                        @click:clear="company.driving_license_issue_date = null"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="company.driving_license_issue_date"
                                        scrollable
                                        color="green lighten-1"
                                        :min="minDate"
                                        header-color="green lighten-1"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                        text
                                        color="primary"
                                        @click="menu2 = false"
                                        >
                                        Cancel
                                        </v-btn>
                                        <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu2.save(company.driving_license_issue_date)"
                                        >
                                        OK
                                        </v-btn>
                                    </v-date-picker>
                                    </v-menu>
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;">
                                <span >Driver's License Expiry Date</span>

                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="company.driving_license_expiry_date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="company.driving_license_expiry_date"
                                        append-icon="fa-calendar"
                                        readonly
                                        v-bind="attrs"
                                        class="font-size-input input-style"
                                        outlined
                                        clearable
                                        v-on="on"
                                        @click:clear="company.driving_license_expiry_date = null"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="company.driving_license_expiry_date"
                                        scrollable
                                        color="green lighten-1"
                                        :min="minDate"
                                        header-color="green lighten-1"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                        >
                                        Cancel
                                        </v-btn>
                                        <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(company.driving_license_expiry_date)"
                                        >
                                        OK
                                        </v-btn>
                                    </v-date-picker>
                                    </v-menu>
                            </v-col>
                            <v-col cols="12"  md="12" lg="12" style="margin-top: -35px;">
                                <p class="text-h6"> Residential Information </p>
                            </v-col>
                            <v-col cols="12"  md="12" lg="12" style="margin-top: -25px;">
                                <span >Street Address<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="company.address_1"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >City<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="company.city"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >State<span class="required_span"> *</span></span>
                                <v-select
                                    :items="stateOptions"
                                    item-text="name"
                                    item-value="abbreviation"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Select State"
                                    v-model="company.state"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col> 
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Zip Code<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="company.zip"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Housing Type<span class="required_span"> *</span></span>
                                <v-select
                                    :items="['Rent','Own','Other']"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="company.housing_type"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Monthly Rent/Mortgage Amount<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="company.monthly_rent_mortgage_amount"
                                    />
                            </v-col>
                            <v-col cols="12"  md="2" lg="2" style="margin-top: -35px;">
                                <span >Years at Address<span class="required_span"> *</span></span>
                                <v-select
                                    :items="[0,1,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100]"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="company.years_at_address"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="2" lg="2" style="margin-top: -35px;">
                                <span >&nbsp;</span>
                                <v-select
                                    :items="[0,1,2,3,4,5,6,7,8,9,10,11]"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="company.months_at_address"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  sm="12" md="12" lg="12" style="margin-top:-55px;" >
                                <v-checkbox
                                    style=""
                                    v-model="company.checkbox_previous_address"
                                    :label="`Add previous address`"
                                    ></v-checkbox>
                            </v-col>

                            
                            <v-col cols="12"  md="12" lg="12" style="margin-top: -35px;" v-if="company.checkbox_previous_address">
                                <p class="text-h6"> Previous Address </p>
                            </v-col>
                            <v-col cols="12" v-if="company.checkbox_previous_address">
                            <v-row v-for="(address, index) in company.previous_address" :key="index">
                            <v-col cols="12"  md="12" lg="12" style="margin-top: -25px;">
                                <span >Street Address<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="address.address_1"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >City<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="address.city"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >State<span class="required_span"> *</span></span>
                                <v-select
                                    :items="stateOptions"
                                    item-text="name"
                                    item-value="abbreviation"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Select State"
                                    v-model="address.state"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col> 
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Zip Code<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="address.zip"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Housing Type<span class="required_span"> *</span></span>
                                <v-select
                                    :items="['Rent','Own','Other']"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="address.housing_type"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Monthly Rent/Mortgage Amount<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="address.monthly_rent_mortgage_amount"
                                    />
                            </v-col>
                            <v-col cols="12"  md="2" lg="2" style="margin-top: -35px;">
                                <span >Years at Address<span class="required_span"> *</span></span>
                                <v-select
                                    :items="[0,1,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100]"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="address.years_at_address"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="2" lg="2" style="margin-top: -35px;">
                                <span >&nbsp;</span>
                                <v-select
                                    :items="[0,1,2,3,4,5,6,7,8,9,10,11]"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="address.months_at_address"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="12" lg="12" style="margin-top: -35px;">
                            <v-btn
                                text
                                style="margin-left: -15px;"
                                color="blue"
                                    @click="addPreviousAddress"
                            >
                                Add more
                            </v-btn>
                            <v-btn
                                text
                                v-if="company.previous_address.length > 1"
                                color="red"
                                    @click="removePreviousAddress(index)"
                            >
                                Remove
                            </v-btn>
                        </v-col>
                            </v-row>
                        </v-col>
                       <!-- Employment Information -->
                       <v-col cols="12"  md="12" lg="12" style="margin-top: -35px;">
                                <p class="text-h6"> Employment Information </p>
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -25px;">
                                <span >Employer Name<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="company.employer_name"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Title/Position<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="company.employment_position"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Employer Phone Number<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    append-icon="fa-phone"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="company.employment_employer_phone"
                                    />
                            </v-col> 
                            
                            <v-col cols="12"  md="8" lg="8" style="margin-top: -35px;">
                                <span >Monthly Gross Income<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="company.employment_income"
                                    />
                            </v-col>
                         
                            <v-col cols="12"  md="2" lg="2" style="margin-top: -35px;">
                                <span >Years at Job<span class="required_span"> *</span></span>
                                <v-select
                                    :items="[0,1,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100]"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="company.employment_years_at_job"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="2" lg="2" style="margin-top: -35px;">
                                <span >&nbsp;</span>
                                <v-select
                                    :items="[0,1,2,3,4,5,6,7,8,9,10,11]"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="company.employment_months_at_job"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  sm="12" md="12" lg="12" style="margin-top:-55px;" >
                                <v-checkbox
                                    style=""
                                    v-model="company.checkbox_previous_employment"
                                    :label="`Previous employment`"
                                    ></v-checkbox>
                            </v-col>

                            
                            <v-col cols="12"  md="12" lg="12" style="margin-top: -35px;" v-if="company.checkbox_previous_employment">
                                <p class="text-h6"> Previous Employment </p>
                            </v-col>
                            <v-col cols="12" v-if="company.checkbox_previous_employment">
                            <v-row v-for="(job, index) in company.previous_employment" :key="index">
                                <v-col cols="12"  md="4" lg="4" style="margin-top: -25px;">
                                <span >Employer Name<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="job.employer_name"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Title/Position<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="job.employment_position"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Employer Phone Number<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="job.employment_employer_phone"
                                    />
                            </v-col> 
                            
                            <v-col cols="12"  md="8" lg="8" style="margin-top: -35px;">
                                <span >Monthly Gross Income<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="job.employment_income"
                                    />
                            </v-col>
                         
                            <v-col cols="12"  md="2" lg="2" style="margin-top: -35px;">
                                <span >Years at Job<span class="required_span"> *</span></span>
                                <v-select
                                    :items="[0,1,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100]"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="job.employment_years_at_job"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="2" lg="2" style="margin-top: -35px;">
                                <span >&nbsp;</span>
                                <v-select
                                    :items="[0,1,2,3,4,5,6,7,8,9,10,11]"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="job.employment_months_at_job"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="12" lg="12" style="margin-top: -35px;margin-bottom: 20px;">
                            <v-btn
                                text
                                style="margin-left: -15px;"
                                color="blue"
                                    @click="addPreviousEmployment"
                            >
                                Add more
                            </v-btn>
                            <v-btn
                                text
                                v-if="company.previous_employment.length > 1"
                                color="red"
                                    @click="removePreviousEmployment(index)"
                            >
                                Remove
                            </v-btn>
                        </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12"  sm="12" md="12" lg="12" style="margin-top:-55px;" >
                                <v-checkbox
                                    style=""
                                    color="blue"
                                    v-model="company.checkbox_trade_in"
                                    :label="`Add Trade-in?`"
                                    ></v-checkbox>
                            </v-col>
                            <v-col cols="12"  md="12" lg="12" style="margin-top: -35px;" v-if="company.checkbox_trade_in">
                                <p class="text-h6" > Trade-in </p>
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;" v-if="company.checkbox_trade_in">
                                <span >VIN<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="company.trade_in_vin"
                                    />
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;" v-if="company.checkbox_trade_in">
                                <span >Mileage<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="company.trade_in_mileage"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;" v-if="company.checkbox_trade_in">
                                <span >Year<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="company.trade_in_year"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;" v-if="company.checkbox_trade_in">
                                <span >Make<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="company.trade_in_make"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;" v-if="company.checkbox_trade_in">
                                <span >Model<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="company.trade_in_model"
                                    />
                            </v-col>
                           
                        </v-row>
                        </v-form>
                    </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item
                    :key="2"
                    :value="'mobile-tabs-5-2'"
                >
                <v-card flat>
                    <v-card-text >
                        <v-form
                                ref="form_co_buyer"
                                v-model="valid2"
                                lazy-validation
                            >
                        <v-row>
                           
                            <v-col cols="12"  sm="12" md="6" lg="6" >
                                <v-checkbox
                                    style=""
                                    v-model="is_co_buyer"
                                    :label="`Has Co-Buyer?`"
                                    ></v-checkbox>
                            </v-col>
                            <v-col cols="12"  sm="12" md="6" lg="6" >
                                <span >Relationship to Buyer<span class="required_span"> *</span></span>
                                <v-select
                                    :disabled="!is_co_buyer"
                                    :items="['Co-Habitant','Parent','Spouse','Relative','Registered Domestic Partner','Civil Union','Other']"
                                    item-text="name"
                                    item-value="abbreviation"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Relationship Type"
                                    v-model="co_buyer_info"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="12" lg="12" >
                                <p class="text-h6">Personal Information</p>
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;">
                                <span >First Name<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name2"
                                    :disabled="!is_co_buyer"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name2"

                                    v-model="co_buyer.first_name"
                                    />
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;">
                                <span >Last Name<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :disabled="!is_co_buyer"
                                    outlined
                                    :rules="rules_name"
                                    ref="name"
                                    v-model="co_buyer.last_name"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Email<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :disabled="!is_co_buyer"
                                    outlined
                                    :rules="emailRules"
                                    ref="name"
                                    v-model="co_buyer.email"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Cell Phone<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    :disabled="!is_co_buyer"
                                    append-icon="fa-mobile"

                                    class="font-size-input input-style"
                                    width="300px"
                                    outlined
                                     v-mask="'(###) ###-####'"
                                    :rules="rules_name"
                                    ref="name"
                                    v-model="co_buyer.phone_m"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Home Phone (Optional)</span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    :disabled="!is_co_buyer"
                                    append-icon="fa-phone"
                                    class="font-size-input input-style"
                                    width="300px"
                                     v-mask="'(###) ###-####'"
                                    outlined
                                    ref="name"
                                    v-model="co_buyer.phone_h"
                                    />
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;">
                                <span >Date of Birth<span class="required_span"> *</span></span>
                                <v-text-field
                                v-model="dob_co"
                                width="300px"
                                outlined
                                :disabled="!is_co_buyer"
                                placeholder="MM/DD/YYYY"
                                v-mask="'##/##/####'"
                                ref="dob_co"
                                class="font-size-input input-style"
                                :suffix="'Age '+ age_co"
                                :rules="rules_name"
                            >
                            
                            </v-text-field>
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;">
                                <span >SSN<span class="required_span"> *</span></span>
                                <v-text-field
                                :rules="rules_name"
                                outlined
                                :disabled="!is_co_buyer"
                                v-mask="'###-##-####'"
                                v-model="co_buyer.ssn"
                                required
                                class="font-size-input input-style"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;">
                                <span >Driver's License Number</span>
                                <v-text-field
                                outlined
                                :disabled="!is_co_buyer"
                                v-model="co_buyer.driving_license_number"
                                required
                                class="font-size-input input-style"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;">
                                <span >Driver's License State</span>
                             
                                <v-select
                                    :items="stateOptions"
                                    item-text="name"
                                    item-value="abbreviation"
                                    height="40px"
                                    :disabled="!is_co_buyer"
                                    class="font-size-input input-style"
                                    placeholder="Select State"
                                    v-model="co_buyer.driving_license_state"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;">
                                <span >Driver's License Issue Date</span>
                                
                                <v-menu
                                    ref="menu4"
                                    v-model="menu4"
                                    :close-on-content-click="false"
                                    :return-value.sync="co_buyer.driving_license_issue_date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="co_buyer.driving_license_issue_date"
                                        append-icon="fa-calendar"
                                        readonly
                                        v-bind="attrs"
                                        class="font-size-input input-style"
                                        outlined
                                        :disabled="!is_co_buyer"
                                        clearable
                                        v-on="on"
                                        @click:clear="co_buyer.driving_license_issue_date = null"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="co_buyer.driving_license_issue_date"
                                        scrollable
                                        color="green lighten-1"
                                        :min="minDate"
                                        header-color="green lighten-1"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                        text
                                        color="primary"
                                        @click="menu4 = false"
                                        >
                                        Cancel
                                        </v-btn>
                                        <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu4.save(co_buyer.driving_license_issue_date)"
                                        >
                                        OK
                                        </v-btn>
                                    </v-date-picker>
                                    </v-menu>
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" style="margin-top: -35px;">
                                <span >Driver's License Expiry Date</span>

                                <v-menu
                                    ref="men3"
                                    v-model="menu3"
                                    :close-on-content-click="false"
                                    :return-value.sync="co_buyer.driving_license_expiry_date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="co_buyer.driving_license_expiry_date"
                                        append-icon="fa-calendar"
                                        readonly
                                        :disabled="!is_co_buyer"
                                        v-bind="attrs"
                                        class="font-size-input input-style"
                                        outlined
                                        clearable
                                        v-on="on"
                                        @click:clear="co_buyer.driving_license_expiry_date = null"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="co_buyer.driving_license_expiry_date"
                                        scrollable
                                        color="green lighten-1"
                                        :min="minDate"
                                        header-color="green lighten-1"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                        text
                                        color="primary"
                                        @click="menu3 = false"
                                        >
                                        Cancel
                                        </v-btn>
                                        <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu3.save(co_buyer.driving_license_expiry_date)"
                                        >
                                        OK
                                        </v-btn>
                                    </v-date-picker>
                                    </v-menu>
                            </v-col>
                            <v-col cols="12"  md="12" lg="12" style="margin-top: -35px;">
                                <p class="text-h6"> Residential Information </p>
                            </v-col>
                            <v-col cols="12"  md="12" lg="12" style="margin-top: -25px;">
                                <span >Street Address<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    :disabled="!is_co_buyer"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="co_buyer.address_1"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >City<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    :disabled="!is_co_buyer"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="co_buyer.city"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >State<span class="required_span"> *</span></span>
                                <v-select
                                    :items="stateOptions"
                                    item-text="name"
                                    item-value="abbreviation"
                                    height="40px"
                                    :disabled="!is_co_buyer"
                                    class="font-size-input input-style"
                                    placeholder="Select State"
                                    v-model="co_buyer.state"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col> 
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Zip Code<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    :disabled="!is_co_buyer"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="co_buyer.zip"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Housing Type<span class="required_span"> *</span></span>
                                <v-select
                                    :items="['Rent','Own','Other']"
                                    height="40px"
                                    :disabled="!is_co_buyer"
                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="co_buyer.housing_type"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Monthly Rent/Mortgage Amount<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    :disabled="!is_co_buyer"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="co_buyer.monthly_rent_mortgage_amount"
                                    />
                            </v-col>
                            <v-col cols="12"  md="2" lg="2" style="margin-top: -35px;">
                                <span >Years at Address<span class="required_span"> *</span></span>
                                <v-select
                                    :items="[0,1,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100]"
                                    height="40px"
                                    :disabled="!is_co_buyer"
                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="co_buyer.years_at_address"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="2" lg="2" style="margin-top: -35px;">
                                <span >&nbsp;</span>
                                <v-select
                                    :items="[0,1,2,3,4,5,6,7,8,9,10,11]"
                                    height="40px"
                                    :disabled="!is_co_buyer"
                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="co_buyer.months_at_address"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  sm="12" md="12" lg="12" style="margin-top:-55px;" >
                                <v-checkbox
                                    style=""
                                    :disabled="!is_co_buyer"
                                    v-model="co_buyer.checkbox_previous_address"
                                    :label="`Add previous address`"
                                    ></v-checkbox>
                            </v-col>

                            
                            <v-col cols="12"  md="12" lg="12" style="margin-top: -35px;" v-if="co_buyer.checkbox_previous_address">
                                <p class="text-h6"> Previous Address </p>
                            </v-col>
                            <v-col cols="12" v-if="co_buyer.checkbox_previous_address">
                            <v-row v-for="(address, index) in co_buyer.previous_address" :key="index">
                            <v-col cols="12"  md="12" lg="12" style="margin-top: -25px;">
                                <span >Street Address<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    :disabled="!is_co_buyer"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="address.address_1"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >City<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    :disabled="!is_co_buyer"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="address.city"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >State<span class="required_span"> *</span></span>
                                <v-select
                                    :items="stateOptions"
                                    item-text="name"
                                    :disabled="!is_co_buyer"
                                    item-value="abbreviation"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Select State"
                                    v-model="address.state"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col> 
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Zip Code<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    :disabled="!is_co_buyer"

                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="address.zip"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Housing Type<span class="required_span"> *</span></span>
                                <v-select
                                :disabled="!is_co_buyer"

                                    :items="['Rent','Own','Other']"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="address.housing_type"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Monthly Rent/Mortgage Amount<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    :disabled="!is_co_buyer"

                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="address.monthly_rent_mortgage_amount"
                                    />
                            </v-col>
                            <v-col cols="12"  md="2" lg="2" style="margin-top: -35px;">
                                <span >Years at Address<span class="required_span"> *</span></span>
                                <v-select
                                    :items="[0,1,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100]"
                                    height="40px"
                                    :disabled="!is_co_buyer"

                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="address.years_at_address"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="2" lg="2" style="margin-top: -35px;">
                                <span >&nbsp;</span>
                                <v-select
                                :disabled="!is_co_buyer"

                                    :items="[0,1,2,3,4,5,6,7,8,9,10,11]"
                                    height="40px"
                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="address.months_at_address"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="12" lg="12" style="margin-top: -35px;">
                            <v-btn
                                text
                                :disabled="!is_co_buyer"

                                style="margin-left: -15px;"
                                color="blue"
                                    @click="addPreviousAddress"
                            >
                                Add more
                            </v-btn>
                            <v-btn
                                text
                                :disabled="!is_co_buyer"

                                v-if="co_buyer.previous_address.length > 1"
                                color="red"
                                    @click="removePreviousAddress(index)"
                            >
                                Remove
                            </v-btn>
                        </v-col>
                            </v-row>
                        </v-col>
                       <!-- Employment Information -->
                       <v-col cols="12"  md="12" lg="12" style="margin-top: -35px;">
                                <p class="text-h6"> Employment Information </p>
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -25px;">
                                <span >Employer Name<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    :disabled="!is_co_buyer"

                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="co_buyer.employer_name"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Title/Position<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    :disabled="!is_co_buyer"

                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="co_buyer.employment_position"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Employer Phone Number<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    :disabled="!is_co_buyer"

                                    append-icon="fa-phone"
                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="co_buyer.employment_employer_phone"
                                    />
                            </v-col> 
                            
                            <v-col cols="12"  md="8" lg="8" style="margin-top: -35px;">
                                <span >Monthly Gross Income<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    :disabled="!is_co_buyer"

                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="co_buyer.employment_income"
                                    />
                            </v-col>
                         
                            <v-col cols="12"  md="2" lg="2" style="margin-top: -35px;">
                                <span >Years at Job<span class="required_span"> *</span></span>
                                <v-select
                                    :items="[0,1,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100]"
                                    height="40px"
                                    :disabled="!is_co_buyer"

                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="co_buyer.employment_years_at_job"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="2" lg="2" style="margin-top: -35px;">
                                <span >&nbsp;</span>
                                <v-select
                                    :items="[0,1,2,3,4,5,6,7,8,9,10,11]"
                                    height="40px"
                                    :disabled="!is_co_buyer"
                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="co_buyer.employment_months_at_job"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  sm="12" md="12" lg="12" style="margin-top:-55px;" >
                                <v-checkbox
                                    style=""
                                    :disabled="!is_co_buyer"

                                    v-model="co_buyer.checkbox_previous_employment"
                                    :label="`Previous employment`"
                                    ></v-checkbox>
                            </v-col>

                            
                            <v-col cols="12"  md="12" lg="12" style="margin-top: -35px;" v-if="co_buyer.checkbox_previous_employment">
                                <p class="text-h6"> Previous Employment </p>
                            </v-col>
                            <v-col cols="12" v-if="co_buyer.checkbox_previous_employment">
                            <v-row v-for="(job, index) in co_buyer.previous_employment" :key="index">
                                <v-col cols="12"  md="4" lg="4" style="margin-top: -25px;">
                                <span >Employer Name<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    :disabled="!is_co_buyer"

                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="job.employer_name"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Title/Position<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    :disabled="!is_co_buyer"

                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="job.employment_position"
                                    />
                            </v-col>
                            <v-col cols="12"  md="4" lg="4" style="margin-top: -35px;">
                                <span >Employer Phone Number<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    :disabled="!is_co_buyer"

                                    name="name"
                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="job.employment_employer_phone"
                                    />
                            </v-col> 
                            
                            <v-col cols="12"  md="8" lg="8" style="margin-top: -35px;">
                                <span >Monthly Gross Income<span class="required_span"> *</span></span>
                                <v-text-field
                                    id="name"
                                    name="name"
                                    :disabled="!is_co_buyer"

                                    class="font-size-input input-style"
                                    width="300px"
                                    :rules="rules_name"
                                    outlined
                                    ref="name"

                                    v-model="job.employment_income"
                                    />
                            </v-col>
                         
                            <v-col cols="12"  md="2" lg="2" style="margin-top: -35px;">
                                <span >Years at Job<span class="required_span"> *</span></span>
                                <v-select
                                    :items="[0,1,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100]"
                                    height="40px"
                                    :disabled="!is_co_buyer"

                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="job.employment_years_at_job"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="2" lg="2" style="margin-top: -35px;">
                                <span >&nbsp;</span>
                                <v-select
                                    :items="[0,1,2,3,4,5,6,7,8,9,10,11]"
                                    height="40px"
                                    :disabled="!is_co_buyer"

                                    class="font-size-input input-style"
                                    placeholder="Housing Type"
                                    v-model="job.employment_months_at_job"
                                    :rules="rules_name"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12"  md="12" lg="12" style="margin-top: -35px;margin-bottom: 20px;">
                            <v-btn
                                text
                                :disabled="!is_co_buyer"

                                style="margin-left: -15px;"
                                color="blue"
                                    @click="addPreviousEmployment"
                            >
                                Add more
                            </v-btn>
                            <v-btn
                                text
                                :disabled="!is_co_buyer"

                                v-if="co_buyer.previous_employment.length > 1"
                                color="red"
                                    @click="removePreviousEmployment(index)"
                            >
                                Remove
                            </v-btn>
                        </v-col>
                            </v-row>
                        </v-col>
                        </v-row>
                        </v-form>
                    </v-card-text>
                    </v-card>
                </v-tab-item>
                </v-tabs-items>
            </v-card>
          <v-row align="center" justify="center" class="mt-10">
            <v-col cols="12" >
                <p style="font-size: 14px;
                    color: #999999;
                    margin-top:-40px;
                    line-height: 150%;" >By clicking "Submit", I, the undersigned, (a) for the purpose of securing credit, certify the below representations to be correct; (b) authorize financial institutions, as they consider necessary and appropriate, to obtain consumer credit reports on me periodically and to gather employment history, and (c) understand that we, or any financial institution to whom this application is submitted, will retain this application whether or not it is approved, and that it is the applicant's responsibility to notify the creditor of any change of name, address, or employment. We and any financial institution to whom this application is submitted, may share certain non-public personal information about you with your authorization or as provided by law.</p>
                <p style="font-size: 14px;
            color: #999999;
            line-height: 150%;" >I consent to be contacted by the dealer at any telephone number or Email I provide, including, without limitation, communications sent via text message to my cell phone or communications sent using an autodialer or prerecorded message. This acknowledgment constitutes my written consent to receive such communications. I have read and agree to the Privacy Policy of this dealer.
          </p>
        </v-col>
            <v-col cols="12"  sm="12" md="12" lg="12" style="margin-top:-25px;" >
                <v-checkbox
                    style=""
                    v-model="checkbox"
                    :rules="[v => !!v || 'You must agree to continue!']"
                    color="red"
                    :label="`I accept all the above terms`"
                    ></v-checkbox>
            </v-col>
           <v-col cols="12"  sm="12" md="12" lg="12" style="text-align: center;">
                <v-btn
                    :disabled="isDisable"
                    rounded
                    color="green"
                    style="height: 53px;
                        padding: 0 40px;
                        text-transform: none;
                        margin-bottom: 50px;" 
                        @click="checkForm"
                        :loading="loading"
                    :dark="isDark"
                >
                    Submit
                </v-btn>
            </v-col>
           
          </v-row>
        </v-col>
        </v-row>
        <v-dialog
        v-model="dialogClientExist"
        persistent
        max-width="290"
        >
        <v-card>
            <v-card-title class="text-h5">
            Please Confirm
            </v-card-title>
            <v-card-text>One client profile found with same email address. Would you like to continue?</v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="dialogClientExist = false"
            >
                Disagree
            </v-btn>
            <v-btn
                color="green darken-1"
                text
                @click="checkForm2(),clientExistFlag = true"
            >
                Agree
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
    </v-container>
    </div>
    </v-main>
 </v-app>
</template>

<script>
import DateDropdown from 'vue-date-dropdown'
import VueRecaptcha from 'vue-recaptcha'
import moment from 'moment'
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
export default {
    components: {
        DateDropdown,
        'vue-recaptcha' : VueRecaptcha,
        'RotateLoader': ClipLoader
    },
    data()  {
        return {
            address: '',
            dob:'',
            age: 0,
            verifyLoad: false,
            dialogClientExist: false,
            verfiedFlag : false,
            tabs : '',
            base_url_img : process.env.VUE_APP_WASBI_URL,
            loadingLogo : false,
            backgroundLogo: localStorage.getItem('admin_bg'),
            verifyUser: '',
            menu2: false,
            menu3: false,
            menu4: false,
            valid : true,
            valid2: true,
            age : 0,
            age_co : 0,
            dob :'',
            dob_co :'',
            rules: [
                value => !!value || 'Required.',
            ],
            temp_email  : localStorage.getItem('temp_email') ? true : false,
            company_info : '',
            rules_name: [
            v => !!v || 'Field is required',
            ],
            emailRules: [
                            v => !!v || 'E-mail is required',
                            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                        ],
            company : {
                first_name: '',
                last_name: '',
                name: "",
                email: "",
                dob: "",
                password: "",
                password_confirmation: "",
                address: '',
                city: '',
                state: '',
                zip:'',
                phone_m: '',
                phone_h: '',
                driving_license_number: '',
                driving_license_state: '',
                driving_license_issue_date: '',
                driving_license_expiry_date: '',
                recaptcha: '',
                address_1: '',
                city: '',
                state: '',
                zip:'',
                housing_type: '',
                monthly_rent_mortgage_amount: '',
                years_at_address: '',
                months_at_address: '',
                checkbox_previous_address : false,
                previous_address : [{
                    address_1: '',
                    city: '',
                    state: '',
                    zip:'',
                    housing_type: '',
                    monthly_rent_mortgage_amount: '',
                    years_at_address: '',
                    months_at_address: '',
                }],
                checkbox_previous_employment : false,
                previous_employment : [{
                    employment_name: '',
                    employment_position: '',
                    employment_employer_phone: '',
                    employment_income:'',
                    employment_years_at_job: '',
                    employment_months_at_job: '',
                }],
                employment_name: '',
                employment_position: '',
                employment_employer_phone: '',
                employment_income:'',
                employment_years_at_job: '',
                employment_months_at_job: '',
                checkbox_trade_in : false,
                trade_in_vin : '',
                trade_in_make : '',
                trade_in_model : '',
                trade_in_year : '',
                trade_in_mileage : '',
                },
                is_co_buyer : false,
                co_buyer_info : '',
            co_buyer : {
                first_name: '',
                last_name: '',
                name: "",
                email: "",
                dob: "",
                password: "",
                password_confirmation: "",
                address: '',
                city: '',
                state: '',
                zip:'',
                phone_m: '',
                phone_h: '',
                driving_license_number: '',
                driving_license_state: '',
                driving_license_issue_date: '',
                driving_license_expiry_date: '',
                recaptcha: '',
                address_1: '',
                city: '',
                state: '',
                zip:'',
                housing_type: '',
                monthly_rent_mortgage_amount: '',
                years_at_address: '',
                months_at_address: '',
                checkbox_previous_address : false,
                previous_address : [{
                    address_1: '',
                    city: '',
                    state: '',
                    zip:'',
                    housing_type: '',
                    monthly_rent_mortgage_amount: '',
                    years_at_address: '',
                    months_at_address: '',
                }],
                checkbox_previous_employment : false,
                previous_employment : [{
                    employment_name: '',
                    employment_position: '',
                    employment_employer_phone: '',
                    employment_income:'',
                    employment_years_at_job: '',
                    employment_months_at_job: '',
                }],
                employment_name: '',
                employment_position: '',
                employment_employer_phone: '',
                employment_income:'',
                employment_years_at_job: '',
                employment_months_at_job: '',
                checkbox_trade_in : false,
                trade_in_vin : '',
                trade_in_make : '',
                trade_in_model : '',
                trade_in_year : '',
                trade_in_mileage : '',
                },
            partner : {
                first_name : "",
                last_name : '',
                middle_name: '',
                email : localStorage.getItem('temp_email') ? localStorage.getItem('temp_email') : '',
                is_email : false ,
                phone_h : '',
                phone_w : '',
                dob: '',
                is_exist : "",
                ssn : '',
                phone_m : '',
                fax : '',
                address :'',
                address1: '',
                city : '',
                state : '',
                zip : '',
                country :'United States',
                memo : '',
                is_verified:  0,
                document : '',
                suffix:'',
                recaptcha: '',
                user_name: "",
                password: localStorage.getItem('temp_pass') ? localStorage.getItem('temp_pass') :"123456",
                confirm_password: "",
                crc_id : ""

            },
            isDisable :false,
            loading: false,
            isDark: true,
            clientExistFlag : false,
            existOption: ["Yes","No"],
            stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
            year : new Date().getFullYear(),
            form: {
            // Some form values...
                recaptcha: '',
            },
            loadingFlag: false,
            url: '',
            sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
        }
    },
    created () {
        this.url = window.location.origin;//this.$route.params.url ? this.$route.params.url : '';
        if(this.url)
            this.getCompanyInfo(this.url)
        else
            this.loadingFlag = true;
    },
    watch: {
      dob (val) {
        this.age = val ?  moment().diff(this.dob, 'years') :0
        if(isNaN(this.age))
          this.age = 0;
        this.company.dob = val
      },
      dob_co (val) {
        this.age_co = val ?  moment().diff(this.dob_co, 'years') :0
        if(isNaN(this.age_co))
          this.age_co = 0;
        this.co_buyer.dob = val
      },
    },
    beforeCreate: function () {
        if (localStorage.getItem('token')) {
            if(localStorage.getItem('is_admin'))
                this.$router.push('/admin/dashboard')
            else
                this.$router.push('/dashboard')
        }
    },
    methods : {
        getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            this.company_info = response.data.company;
            // this.loadingFlag = true;
            this.loadingLogo = true;
          })

},
        onCaptchaVerified: function (recaptchaToken) {
        this.company.recaptcha = recaptchaToken
        this.validateCaptcha = true
        },
        onCaptchaExpired: function () {
        this.$refs.recaptcha.reset();
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.company.address = addressData.name;
            this.company.city = addressData.locality;
            this.company.state = addressData.administrative_area_level_1;
            this.company.zip = addressData.postal_code
        },
        addPreviousAddress(){
            this.company.previous_address.push({
                    address_1: '',
                    city: '',
                    state: '',
                    zip:'',
                    housing_type: '',
                    monthly_rent_mortgage_amount: '',
                    years_at_address: '',
                    months_at_address: '',
                })
        },
        removePreviousAddress(index){
            this.company.previous_address.splice(index, 1)
        },
        addPreviousEmployment(){
            this.company.previous_employment.push({
                employment_name: '',
                    employment_position: '',
                    employment_employer_phone: '',
                    employment_income:'',
                    employment_years_at_job: '',
                    employment_months_at_job: '',
                })
        },
        removePreviousEmployment(index){
            this.company.previous_employment.splice(index, 1)
        },
        isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        verifyCrc(){
            let self = this;
            let flag = 1;
            if (!this.partner.crc_id) {
                Vue.$toast.error('Customer ID is required', {
                    position: 'top-right'
                });
                flag = 0;
            }else{
                self.verifyLoad = true;
                axios({
                method: "POST",
                 
                url: "/api/auth/verify-crc",
                data:{crc_id : self.partner.crc_id},
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                    if(response.data.data.success == 'True'){
                        self.verifyUser = response.data.data.result;
                        self.verfiedFlag = true;
                    }else{
                        Vue.$toast.error('Invalid customer id', {
                            position: 'top-right'
                        });
                    }
                    self.verifyLoad = false
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    self.verifyLoad = flase;

                });
            }
        },
        autofill(){
            let self = this;
            self.partner.first_name = self.verifyUser.firstname
            self.partner.last_name = self.verifyUser.lastname
            if(!self.temp_email)
                self.partner.email = self.verifyUser.email
            self.partner.middle_name = self.verifyUser.middlename
            self.partner.phone_m = self.verifyUser.phone_mobile
            self.partner.phone_h = self.verifyUser.phone_home
            self.partner.phone_w = self.verifyUser.phone_work
            self.partner.ssn = self.verifyUser.ssno
            self.address = self.verifyUser.street_address
            if(self.verifyUser.state){
                self.stateOptions.forEach(element => {
                    if(self.verifyUser.state.toUpperCase()  == element.name)
                        self.partner.state = element.abbreviation
                });
            }
            self.partner.zip = self.verifyUser.zip
            self.partner.city = self.verifyUser.city
            if(self.verifyUser.birth_date){
                var temp_dob = self.verifyUser.birth_date.split("/");
                self.partner.dob = temp_dob[1]+'.'+temp_dob[0]+'.'+temp_dob[2]
                console.log(self.partner.dob);
            }
            
        },
        checkForm(){
            let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        let buyer = this.company;
        buyer.previous_address = this.company.previous_address;
        buyer.previous_employment = this.company.previous_employment;
        buyer.dob = this.dob;


        let co_buyer = this.co_buyer;
        co_buyer.previous_address = this.co_buyer.previous_address;
        co_buyer.previous_employment = this.co_buyer.previous_employment;
        co_buyer.dob = this.dob_co;

        if (this.$refs.form_buyer.validate() && this.is_co_buyer == false || (this.is_co_buyer == true && this.$refs.form_co_buyer.validate())) {
            self.isDisable = true;
            self.isDark = false;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/register-buyer",
          data: {buyer : buyer,co_buyer_flag: this.is_co_buyer,co_buyer_info: this.co_buyer_info ,co_buyer: co_buyer},
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('You have successfully registered with us.', {
                    position: 'top-right'
                  })
                  this.$router.push('thanks')

            } else {
                self.isDisable = false;
            self.isDark = true;
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
                self.isDisable = false;
                self.isDark = true;
            if(err.response.data.flag){
                if(err.response.data.flag == 2){
                     Vue.$toast.error('Company exist with same email address!', {
                    position: 'top-right'
                    });
                }
                if(err.response.data.flag == 3){
                    this.dialogClientExist = true;
                }
            }else{
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });      
            }      
          });
        }
        },
        checkForm2(){
            let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.company.name) {
            Vue.$toast.error('Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.contact_email && flag == 1) {
            Vue.$toast.error('Contact email is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.contact_phone && flag == 1) {
            Vue.$toast.error('Contact phone is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        
     
        
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(this.company.contact_email) == false && flag == 1 ) {
            Vue.$toast.error('Invalid Email format', {
            position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }
         if (!this.company.recaptcha && flag == 1) {
            Vue.$toast.error('Recaptcha is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        this.company.email = this.company.contact_email;
        

        if (flag == 1) {
            self.isDisable = true;
            self.isDark = false;
          self.company.address_1 = self.company.address;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/register-company-revised",
          data: self.company
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('You have successfully registered with us.', {
                    position: 'top-right'
                  })
                  this.dialogClientExist = false;
                  this.$router.push('admin/')

            } else {
                self.isDisable = false;
            self.isDark = true;
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
                self.isDisable = false;
                self.isDark = true;
            if(err.response.data.flag){
                if(err.response.data.flag == 2){
                     Vue.$toast.error('Company exist with same email address!', {
                    position: 'top-right'
                    });
                }
                if(err.response.data.flag == 3){
                    this.dialogClientExist = true;
                }
            }else{
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });      
            }      
          });
        }
        }
    }
}
</script>

<style>
/* .image-logo2{
position: absolute;
margin: -78px -5px -4px 170px;
} */
.date-dropdown-container{
    height: 85px;
}
.date-dropdown-select{
    border: 1px solid #9E9E9E!important;
    padding: 15px!important;
}
.required_span{
    color:red;
}

</style>
<style>
    .input-login .v-icon{
        color: #FDE038!important;
    }
    .input-login input{
        color: #FDE038!important;
    }
    .input-login .v-label{
      color: #FDE038!important;
    }
    .input-login #input-6{
      color: #FDE038!important;
    }
    .input-login #pass{
      color: #FDE038!important;
    }
    .input-login #input-19{
      color: #FDE038!important;
    }
    </style>