<template>
<v-app id="inspire" style="background:#F9F9F9;">

<vue-header-admin></vue-header-admin>
<v-main>
    <v-container fluid class="px-6 py-6">
        <v-row
            align="center"
            justify="center"
            class="mt-10"
          >
            <v-col cols="12" md="12">
               <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                <v-card-text style="margin-bottom: -30px;" >
                  <v-row>
                      
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <!-- <h1 class="text-center mt-5 mb-5">Company Settings</h1> -->
                    <h5 class="text-h4 font-weight-bold text-typo">Payment Gateway({{totalCount}})</h5>
                    </v-col>
                    <v-col cols="12"
                      md="12" >
                         <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        style="float: left;"
                                        @click="dialogAssign = true"
                                    >
                                        Add Payment Gateway
                                    </v-btn>
                      </v-col>
                    <v-col cols="12"
                      md="12">
                      
                        <v-data-table
                            :headers="headers_jobs"
                            :options.sync="datatable_options"
                            :items="users"
                            loading-text="Loading... Please wait"
                            :page.sync="page"
                            :items-per-page="itemsPerPage"
                            hide-default-footer
                            class="table"
                        >
                     
                        <template v-slot:item.status="{ item }">
                            {{item.status == 1 ? 'Active' :'Inactive'}}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                        <template v-slot:item.value="{ item }">
                            {{item.type =='Percentage' ? item.value+'%' : '$'+item.value}}
                        </template>
                        <template v-slot:item.password="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="dialogDetail = true,selectedItem = JSON.parse(item.credentials)"
                            x-small
                            color="green"
                          >
                             <v-icon size="20" class="material-icons-round opacity-10" dark>
                              copy
                            </v-icon>
                          </v-btn>
                        </template>
                      
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                        <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="productEditOpen(item)"
                            x-small
                            color="blue"
                          >
                            <v-icon class="material-icons-round opacity-10"  size="20" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItem(item.id)"
                            x-small
                            color="red"
                          >
                             <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                            prev-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                             circle
                            color="#4CAF50"
                                v-model="page"
                                :length="pageCount"
                                @input="getUsers"
                                class="mb-5"
                            ></v-pagination>
                            </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-dialog
        persistent
      v-model="dialogAssign"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-if="!promo.id">
          Add new Payment Gateway
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>
          Edit Payment Gateway
        </v-card-title>
        <v-card-text style="margin-top:30px;"> 
          <v-text-field
                v-model="promo.name"
                label="Name"
          ></v-text-field>
                 <v-select
                            placeholder="Select type"
                            label="Type"
                            :disabled="loadingAssign"
                            v-model="promo.type"
                            :items="['Paypal','Stripe','Authorize.net','Jotform']"
                            ></v-select>
          <v-text-field
          label="API User"
            v-if="promo.type == 'Paypal'"
                v-model="promo.credentials.api_user"
          ></v-text-field>
          <v-text-field
          label="API Login ID"
            v-if="promo.type == 'Authorize.net'"
                v-model="promo.credentials.api_user"
          ></v-text-field>
          <v-text-field
          v-if="promo.type != 'Jotform' && promo.type != 'Authorize.net' "
          label="Secret"
                v-model="promo.credentials.api_password"
          ></v-text-field>
          <v-text-field
          v-if="promo.type == 'Authorize.net' "
          label="Transaction Key"
                v-model="promo.credentials.api_password"
          ></v-text-field>
          <v-text-field
           v-if="promo.type != 'Jotform' && promo.type != 'Authorize.net'"
           label="Publisher Key"
                v-model="promo.credentials.api_signature"
          ></v-text-field>
             
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            v-if="!promo.id"
            text
            :disabled="loadingAssign"
            @click="addPromo()"
          >
            Submit
          </v-btn>
          <v-btn
          v-else
            color="primary"
            text
            :disabled="loadingAssign"
            @click="editPromo()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAssign = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogDetail"
      width="700"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Credentials
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <h3 style="color:#5D6167;">API User: <span style="color:green;">{{selectedItem.api_user}}</span></h3>
        </v-card-text>
         <v-card-text style="margin-top:30px;"> 
          <h3 style="color:#5D6167;">API Password: <span style="color:green;">{{selectedItem.api_password}}</span></h3>
        </v-card-text>
         <v-card-text style="margin-top:30px;"> 
          <h3 style="color:#5D6167;">API Signature: <span style="color:green;">{{selectedItem.api_signature}}</span></h3>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
         
           <v-btn
            color="primary"
            text
            @click="dialogDetail = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
    <vue-footer-admin></vue-footer-admin>
</v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
export default {
components: {
      VueTelInput,
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        id: '',
        name:'',
        phone:'',
        datatable_options:{},
        password : '',
        confpassword : '',
        role : '',
        companyname : '',
        totalCount : 0,
        pageCount : 1,
        selectedItem: '',
        dialogDetail : false,
        promo: {
          name : '',
          type : 'Paypal',
          credentials : {
            api_user :  "",
            api_password : "",
            api_signature : ""
          },
        },
        sector : '',
        limit: 10,
        loadingAssign : false,
        no_of_employee : '',
        dialogAssign: false,
        showPassword : false,
        phoneFlag:0,
        emailFlag:0,
        country :'',
        users : [],
        page:1,
        itemsPerPage:10,
        otp_email:'',
        otp_phone:'',
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        user_email:'',
        status:0,
        headers_jobs: [
          { text: 'Name', value: 'name',
            sortable: true },
          { text: 'Type', value: 'type',
            sortable: true },
        
           
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            
             { text: 'Credentials', value: 'password',
            sortable: false,width: '150' },
            { text: 'Edit', value: 'edit',
            sortable: false,width: '50' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        settings :{
            day1: 30,
            day2: 21,
            day2_price: 1500,
            pricing :{
                family_plan_price_monthly : "",
                family_plan_price_yearly : "",
                individual_plan_price_monthly : "",
                individual_plan_price_yearly : "",
                individual_plus_plan_price_monthly : "",
                individual_plus_plan_price_yearly : "",
            }

        },
        tab: null,


      }
    },

    methods: {
      addPromo(){
              this.loadingAssign = true;
              axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-payment-gateway",
          data: this.promo
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Payment gateway added successfully', {
                      position: 'top-right'
                      });
                      this.dialogAssign = false;
                      this.loadingAssign = false;
                      this.getUsers();
                      this.promo.name = '';
                      this.promo.value = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error(err.message, {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      editPromo(){
              this.loadingAssign = true;
              axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-payment-gateway",
          data: this.promo
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Payment gateway added successfully', {
                      position: 'top-right'
                      });
                      this.dialogAssign = false;
                      this.loadingAssign = false;
                      this.getUsers();
                      this.promo.name = '';
                      this.promo.value = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error(err.message, {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      productEditOpen(item){
      this.promo = item
      this.promo.credentials = typeof this.promo.credentials === 'object' ? this.promo.credentials : JSON.parse(this.promo.credentials)
      this.dialogAssign=true
     },
      deleteItem(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-payment-gateway/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getUsers();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
    getUsers(){
            this.loading = true;
            this.offset = (this.page - 1) * 10
            this.selected_project = name;
            let req = {
              limit : this.limit,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-payment-gateway",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.users = data.data;
                this.pageCount = data.total_page
                this.totalCount = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },




    saveSettings: function(e) {

        axios({
          method: "POST",
           
          url: "/api/update-company-settings",
          data: {
            id: this.id,
            settings: this.settings
          }
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Settings saved', {
                    position: 'top-right'
                  })
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
    },

 },

created() {
  if(!localStorage.getItem('token')){
    this.$router.push('/login')
    }else{
      console.log('created called.');
      this.getUsers();
    }
      
    },

}
</script>

