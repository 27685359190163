<template>
    <div>
      <v-card
    :loading="loading"
    class="mx-auto mb-3"
    max-width="374"
    elevation="8"
    v-for="user in users" v-bind:key="user.id" v-if="user.id"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img
      height="250"
      :src="user.images[0].image"
    ></v-img>

    <v-card-title>{{user.year}} {{user.make}} {{user.model}}</v-card-title>

    <v-card-text>
      <v-row
        align="center"
        class="mx-0 mb-1 mt-1"
      >
      <v-btn
                    elevation="0"
                    small
                    v-if="user.category_cow"
                    :ripple="false"
                    height="21"
                    class="
                      border-radius-md
                      font-weight-bolder
                      badge-font-size
                      text-success
                      mr-2
                    "
                    color="#bce2be"
                    >Car Of The Week</v-btn
                  >
                  <v-btn
                    elevation="0"
                    small
                     v-if="user.category_cl"
                    :ripple="false"
                    height="21"
                    class="
                      border-radius-md
                      font-weight-bolder
                      badge-font-size
                      text-success
                      mr-2
                    "
                    color="#bce2be"
                    >{{  'Clearance' }}</v-btn
                  >
                  <v-btn
                    elevation="0"
                    small
                    v-if="!user.category_cl && !user.category_cow"
                    :ripple="false"
                    height="21"
                    class="
                      border-radius-md
                      font-weight-bolder
                      badge-font-size
                      text-success
                      mr-2
                    "
                    color="#bce2be"
                    >{{  'New Stock' }}</v-btn
                  >
      </v-row>
      <!-- <h1
                class="text-h1 font-weight-bold mt-4"
                :class=" 'text-black'"
              >
                $2323
              </h1> -->
      <div class="my-4 text-subtitle-1" >
        <span class="text-h4 font-weight-bold mt-2" style="font-family: LatoGoogle,sans-serif;font-weight: 700;color: black;">{{ currencyFormat(user.amount) }}</span> <span class="float-right mt-1">{{numberFormat(user.odometer)}} mi</span>
      </div>

      <!-- <div>
        <v-list-item-subtitle  style="margin-top: -10px;">{{numberFormat(user.odometer)}} mi</v-list-item-subtitle>
      </div> -->
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-actions style="margin-top: -10px;justify-content: center;">
      <v-btn
              class="mt-3 p-5"
                rounded
                style="padding: 15px;"
                color="#272727"
                :dark="true"
                small
                @click="viewDetail(user.id)"
              > View Detail
              </v-btn>
              <v-btn
              class="mt-3 p-5"
                rounded
                style="padding: 15px;"
                small
                color="#272727"
                :dark="true"               
                @click="gotoLinks('https://roundtables.mycarsonline.com/apply-online/')"
              >Get Pre Approved!
              </v-btn>
    </v-card-actions>
    <!-- <v-card-actions    >
     
    </v-card-actions> -->
  
  </v-card>
  <v-dialog
                      v-model="overlay_inventory"
                      width="500"
                    >
                    
                    <div style="padding: 10!important;" >
                    <Query class="mt-5" :color="'black'" :id="selected_item" :ref_id="company.user_id" />
                    </div>
                    </v-dialog>
  </div>
</template>

<script>
import Query from "@/components/Forms/Query.vue";
export default {
props: ["users","company"],
components: {Query},
  data: () => ({
    query: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
       
    },
    overlay_inventory : false,
    selected_item: '',
    base_url_img : process.env.VUE_APP_WASBI_URL,

  }),
  methods: {
    getParentDomain() {
        const hostname = window.location.hostname;
        const domainParts = hostname.split('.');
        
        // Assuming the domain is of the form subdomain.example.com
        if (domainParts.length >= 2) {
            const parentDomain = domainParts.slice(-2).join('.');
            return parentDomain;
        } else {
            return hostname; // For cases like localhost or single part domains
        }
    },
    viewDetail(id){
      if(localStorage.getItem('view-inventory-flag') && localStorage.getItem('view-inventory-flag') == 1){
        this.$router.push('/view-inventory-details/'+id)
      }else{
        this.overlay_inventory = true
        this.selected_item = id
      }
    },
    gotoLinks(link){
      if(!link)
      link = '#'
      const newTab = window.open('', '_blank');
      newTab.location.href = link;
    },
    currencyFormat(value){
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(value);
      },
      numberFormat(value){
        return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(
          value,
  )
      },
    gotoCard(vcard){
      let id = vcard.domain
      let route = '';
      let url = 'http://'+id+'.'+this.getParentDomain();
        window.open(url, '_blank');

    },
  }
}
</script>

<style>

</style>