<template>
    <v-app id="inspire" :style="`background-image: url(${backgroundLogo});`" class="background-thanks">

    <div style="display: flex; justify-content: space-around" class="mt-6 mb-1">
    </div>

    <v-row align="center" justify="center">


        <v-col sm="8" md="4">

        <!-- <v-card class="elevation-4" > -->
            <v-card-text class="mb-0" :style="$vuetify.breakpoint.xs ? 'display: flex; justify-content: space-around; margin-top:0px;' : 'display: flex; justify-content: space-around; margin-top:100px;'">
                <v-icon class="icon-check">mdi-check-circle</v-icon>
            </v-card-text>
             <div :style="$vuetify.breakpoint.xs ? 'display: flex; justify-content: space-around; margin-top:-55px;' : 'display: flex; justify-content: space-around;margin-top: 35px;'" >
                <h1 class="text-h1" style="margin-top:45px;color: white;">Thank you!</h1>
            </div>
            <v-card-text class="mb-0">
                <p class="p-1" style="color: white"><strong>We've received your request. After verify your informations, You'll receive confirmation email within the next 24 hours.</strong></p>
            </v-card-text>

           
        <!-- </v-card> -->
        </v-col>
    </v-row>
</v-app>
</template>

<script>
// import image from "./assets/profile.png"
import axios from "axios";
import Utility from "@/components/common/functions.js";
export default {
props: {
    source: String,
},

data () {
return {
    email : '',
    password : '',
    showPassword : false,
    dialog: false,
    backgroundLogo :  require('@/assets/img/bg-car-thanks.jpg')

}
},
beforeCreate: function () {
    // if (localStorage.getItem('token')) {
    //     this.$router.push('/dashboard')
    // }
},
methods: {


},

}
</script>

<style scoped>
   html {
  overflow: hidden !important;
}

</style>
<style>
.separator {
    display: flex;
    align-items: center;
    text-align: center;
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgba(0,0,0,.6);
}
.separator::before {
    margin-right: .25em;
}
.separator::after {
    margin-left: .25em;
}
.background-thanks{
    background-size: cover !important;
    background-repeat: no-repeat!important;
    background-position: center top!important;
    background-attachment: scroll!important;
    min-height: 100%!important;
}
.header-1{
    color: #150F24;
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-style: normal;
    text-align: center;
    padding: 10px;
    margin-top: 0px;
    font-size: 50px;
}
.p-1{
color: #272343;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    text-align: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.2rem;
}
.icon-check{
    font-size: 48px!important;
    color: rgb(0, 184, 129)!important;
}
</style>
