<template>
    <div>
  <v-card class="card-shadow border-radius-xl text-center mt-3" elevation="8" v-for="user in users" v-bind:key="user.company_id" v-if="user.first_name">
    <v-card-text >
        <v-avatar size="90" class="mt-3" style="box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.5);">
      <img
        :src="base_url_img+user.avatar"
        alt="John"
      >
    </v-avatar>
        <v-col cols="12" style="display: flex; justify-content: space-around; margin-top: 10px; " >
                  <h1 class="mb-1 text-h4 text-typo font-weight-bold">{{user.first_name.toUpperCase()}} {{ user.last_name.toUpperCase() }} </h1>
                  
                  </v-col>
                  <v-col cols="12" style="display: flex; justify-content: space-around" >
                    <p class="font-weight-bold text-body text-sm" style="color: black;margin-top: -15px;">{{user.roles[0].description}}</p>
                  </v-col>
                  <!-- <v-col cols="12" style="display: flex; justify-content: space-around" >
                    <p class="font-weight-light text-body text-sm" style="color: black;margin-top: -45px;">@Round Tables LLC</p>
                  </v-col> -->
    <v-btn
            class="mt-3"
              rounded
              color="#272727"
              :dark="true"
              block
              @click="gotoCard(user)"
              large
            >View Business Card
            </v-btn>
    </v-card-text>
  </v-card>
  </div>
</template>

<script>
export default {
props: ["users"],
  data: () => ({
    query: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
       
    },
    base_url_img : process.env.VUE_APP_WASBI_URL,

  }),
  methods: {
    getParentDomain() {
        const hostname = window.location.hostname;
        const domainParts = hostname.split('.');
        
        // Assuming the domain is of the form subdomain.example.com
        if (domainParts.length >= 2) {
            const parentDomain = domainParts.slice(-2).join('.');
            return parentDomain;
        } else {
            return hostname; // For cases like localhost or single part domains
        }
    },
    gotoCard(vcard){
      let id = vcard.domain
      let route = '';
      let url = 'http://'+id+'.'+this.getParentDomain();
        window.open(url, '_blank');

    },
  }
}
</script>

<style>

</style>