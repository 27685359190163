<template>
  <v-card
    class="card-shadow border-radius-xl"
    :class="$route.name == 'VrDefault' ? 'bg-gradient-default' : ''"
  >
    <div class="px-6 pt-4 pb-0 d-flex" v-if="!single">
      <v-avatar
        color="bg-gradient-success border-radius-xl mt-n8"
        class="shadow"
        height="64"
        width="64"
      >
        <v-icon class="material-icons-round text-white" size="24"
          >language</v-icon
        >
      </v-avatar>
     <div class="d-flex align-items-center">
  <p class="font-weight-bold text-h6 mb-0 ms-3" :class="$route.name == 'VrDefault' ? 'text-white' : 'text-typo'">
    Sales by
  </p>
  <v-select style="margin-top: -15px;margin-left: 15px" v-model="selectedFlag" :items="['States','Agents']" @change="changeFlag"></v-select>
</div>
    </div>
    <v-row>
      <v-col lg="4" md="4" v-if="!single">
        <v-card-text class="py-3" style="height: 400px;overflow-y: scroll">
          <v-list class="py-0 bg-transparent">
            <v-list-item
              v-for="(chat, index) in countries"
              :key="chat.state"
              class="px-4 py-1"
              :class="{ 'border-bottom': index != scans.length - 1 }"
            >
              <!-- <v-img
                :alt="`${chat.title} avatar`"
                :src="chat.avatar"
                max-width="20px"
                class="me-6"
              ></v-img> -->

              <v-list-item-content>
                <span
                  class="text-xs font-weight-bold"
                  :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                >
                  {{selectedFlag == "States" ? "State:" : "Agent:" }}
                </span>
                <v-list-item-title
                  class="text-sm mb-0"
                  :class="
                    $route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'
                  "
                >{{ selectedFlag == 'States' ? chat.state : chat.ref }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="text-center">
                <span
                  class="text-xs font-weight-bold"
                  :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                >
                  Scans:
                </span>
                <v-list-item-title
                  v-text="chat.count"
                  class="text-m mb-0"
                  :class="
                    $route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'
                  "
                ></v-list-item-title>
              </v-list-item-content>

              <!-- <v-list-item-content class="text-center">
                <span
                  class="text-xs font-weight-bold"
                  :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                >
                  Value:
                </span>
                <v-list-item-title
                  v-text="chat.value"
                  class="text-sm mb-0"
                  :class="
                    $route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'
                  "
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="text-center">
                <span
                  class="text-xs font-weight-bold"
                  :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                >
                  Bounce:
                </span>
                <v-list-item-title
                  v-text="chat.bounce"
                  class="text-sm mb-0"
                  :class="
                    $route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'
                  "
                ></v-list-item-title>
              </v-list-item-content> -->
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-col>
      <v-col :lg="single ? '12' : '8'" :md="single ? '12' : '8'">
        <div id="chartdiv" class="mt-5 mb-5 mt-lg-n4 w-100 h-100 overflow-hidden" style="height: 400px"></div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/maps/world-merc";
import "jsvectormap/dist/css/jsvectormap.min.css";


export default {
  name: "sales-country",
  props: ["scans","single"],
  data() {
    return {
      selectedFlag: "States",
      countries: [],
    };
  },
  methods: {
    changeFlag(){
      if (this.selectedFlag == "States") {
        this.countries = this.scans.reduce((result, person) => {
       
          // Find the existing state object or initialize it
          const existingState = result.find(item => item.state == person.state);

          if (existingState) {
            // Increment the count for this state
            existingState.count += 1;
          } else {
            // Add a new state object with count initialized to 1
            result.push({ state: person.state, count: 1 });
          }

          return result;
        }, []);
      }else{
       this.countries = this.scans.reduce((result, person) => {
       
          // Find the existing state object or initialize it
          const existingState = result.find(item => item.ref == person.ref);

          if (existingState) {
            // Increment the count for this state
            existingState.count += 1;
          } else {
            // Add a new state object with count initialized to 1
            result.push({ ref: person.ref, count: 1 });
          }

          return result;
        }, []);
      }
    }
  },
  mounted() {
    // Group by state
    if(!this.single){
        const groupedByState = this.scans.reduce((result, person) => {
          // Find the existing state object or initialize it
          const existingState = result.find(item => item.state === person.state);

          if (existingState) {
            // Increment the count for this state
            existingState.count += 1;
          } else {
            // Add a new state object with count initialized to 1
            result.push({ state: person.state, count: 1 });
          }

          return result;
        }, []);
      this.countries = groupedByState
    }

    // Create root and chart
var root = am5.Root.new("chartdiv"); 

// Set themes
root.setThemes([
  am5themes_Animated.new(root)
]);

var chart = root.container.children.push(
  am5map.MapChart.new(root, {
    panX: "rotateX",
    projection: am5map.geoAlbersUsa()
  })
);

// Create polygon series
var polygonSeries = chart.series.push(
  am5map.MapPolygonSeries.new(root, {
    geoJSON: am5geodata_usaLow
  })
);

polygonSeries.mapPolygons.template.setAll({
  tooltipText: "{name}"
});

polygonSeries.mapPolygons.template.states.create("hover", {
  fill: am5.color(0x297373)
});

var zoomOut = root.tooltipContainer.children.push(am5.Button.new(root, {
  x: am5.p100,
  y: 0,
  centerX: am5.p100,
  centerY: 0,
  paddingTop: 18,
  paddingBottom: 18,
  paddingLeft: 12,
  paddingRight: 12,
  dx: -20,
  dy: 20,
  themeTags: ["zoom"],
  icon: am5.Graphics.new(root, {
    themeTags: ["button", "icon"],
    strokeOpacity: 0.7,
    draw: function(display) {
      display.moveTo(0, 0);
      display.lineTo(12, 0);
    }
  })
}));

zoomOut.get("background").setAll({
  cornerRadiusBL: 40,
  cornerRadiusBR: 40,
  cornerRadiusTL: 40,
  cornerRadiusTR: 40
});
zoomOut.events.on("click", function() {
  if (currentSeries) {
    currentSeries.hide();
  }
  chart.goHome();
  zoomOut.hide();
  currentSeries = regionalSeries.US.series;
  currentSeries.show();
});
zoomOut.hide();

// Load store data
// am5.net.load("https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/TargetStores.json").then(function(result) {
//   var stores = am5.JSONParser.parse(result.response);
//   setupStores(stores);
// });
var stores = this.scans;
var regionalSeries = {};
var currentSeries;
setTimeout(() => {
 setupStores(stores);
}, 1000)
// Parses data and creats map point series for domestic and state-level
function setupStores(data) {
  console.log(data);
  
  // Init country-level series
  regionalSeries.US = {
    markerData: [],
    series: createSeries("stores")
  };
  
  // Set current series
  currentSeries = regionalSeries.US.series;
  
  // Process data
  am5.array.each(data, function(store) {

    // Get store data
    var store = {
      state: store.MAIL_ST_PROV_C,
      long: am5.type.toNumber(store.LNGTD_I),
      lat: am5.type.toNumber(store.LATTD_I),
      location: store.co_loc_n,
      city: store.mail_city_n,
      count: 1,
      ref: store.ref
    };
    
    // Process state-level data
    if (regionalSeries[store.state] == undefined) {
      var statePolygon = getPolygon("US-" + store.state);
      if (statePolygon) {
        
        var centroid = statePolygon.visualCentroid();

        // Add state data
        regionalSeries[store.state] = {
          target: store.state,
          type: "state",
          name: statePolygon.dataItem.dataContext.name,
          count: store.count,
          stores: 1,
          state: store.state,
          ref: '',
          markerData: [],
          geometry: {
            type: "Point",
            coordinates: [centroid.longitude, centroid.latitude]
          }
        };
        regionalSeries.US.markerData.push(regionalSeries[store.state]);

      }
      else {
        // State not found
        return;
      }
    }
    else {
      regionalSeries[store.state].stores++;
      regionalSeries[store.state].count += store.count;
    }
    
    // Process city-level data
    if (regionalSeries[store.city] == undefined) {
      regionalSeries[store.city] = {
        target: store.city,
        type: "city",
        name: store.city,
        count: store.count,
        stores: 1,
        ref: store.ref+'\n',
        state: store.state,
        markerData: [],
        geometry: {
          type: "Point",
          coordinates: [store.long, store.lat]
        }
      };
      regionalSeries[store.state].markerData.push(regionalSeries[store.city]);
    }
    else {
      regionalSeries[store.city].stores++;
      regionalSeries[store.city].count += store.count;
    }

    // Process individual store
    regionalSeries[store.city].markerData.push({
      name: store.location,
      count: store.count,
      stores: 1,
      ref: store.ref+'\n',
      state: store.state,
      geometry: {
        type: "Point",
        coordinates: [store.long, store.lat]
      }
    });
    
  });
  regionalSeries.US.series.data.setAll(regionalSeries.US.markerData);
}

// Finds polygon in series by its id
function getPolygon(id) {
  var found;
  polygonSeries.mapPolygons.each(function(polygon) {
    if (polygon.dataItem.get("id") == id) {
      found = polygon;
    }
  })
  return found;
}

// Creates series with heat rules
function createSeries(heatfield) {
  
  // Create point series
  var pointSeries = chart.series.push(
    am5map.MapPointSeries.new(root, {
      valueField: heatfield,
      calculateAggregates: true
    })
  );
  
  // Add store bullet
  var circleTemplate = am5.Template.new(root);
  pointSeries.bullets.push(function() {
    var container = am5.Container.new(root, {});
    
    var circle = container.children.push(am5.Circle.new(root, {
      radius: 10,
      fill: am5.color(0x000000),
      fillOpacity: 0.7,
      cursorOverStyle: "pointer",
      tooltipText: "{name}:\n[bold]{ref}{stores} scans[/]"
    }, circleTemplate));
    
    var label = container.children.push(am5.Label.new(root, {
      text: "{stores}",
      fill: am5.color(0xffffff),
      populateText: true,
      centerX: am5.p50,
      centerY: am5.p50,
      textAlign: "center"
    }));
    
    // Set up drill-down
    circle.events.on("click", function(ev) {
      
      // Determine what we've clicked on
      var data = ev.target.dataItem.dataContext;

      // No id? Individual store - nothing to drill down to further
      if (!data.target) {
        return;
      }
      
      // Create actual series if it hasn't been yet created
      if (!regionalSeries[data.target].series) {
        regionalSeries[data.target].series = createSeries("count");
        regionalSeries[data.target].series.data.setAll(data.markerData);
      }

      // Hide current series
      if (currentSeries) {
        currentSeries.hide();
      }
      
      // Control zoom
      if (data.type == "state") {
        var statePolygon = getPolygon("US-" + data.state);
        polygonSeries.zoomToDataItem(statePolygon.dataItem);
      }
      else if (data.type == "city") {
        chart.zoomToGeoPoint({
          latitude: data.geometry.coordinates[1],
          longitude: data.geometry.coordinates[0]
        }, 64, true);
      }
      zoomOut.show();

      // Show new targert series
      currentSeries = regionalSeries[data.target].series;
      currentSeries.show();
    });
    
    return am5.Bullet.new(root, {
      sprite: container
    });
  });
  
  // Add heat rule for circles
  pointSeries.set("heatRules", [{
    target: circleTemplate,
    dataField: "value",
    min: 10,
    max: 30,
    key: "radius"
  }])
  
  // Set up drill-down
  // TODO
  
  return pointSeries;
}







    // new jsVectorMap({
    //   selector: "#map",
    //   map: "world_merc",
    //   zoomOnScroll: false,
    //   zoomButtons: false,
    //   selectedMarkers: [1, 3],
    //   markersSelectable: true,
    //   markers: [
    //     {
    //       name: "USA",
    //       coords: [40.71296415909766, -74.00437720027804],
    //     },
    //     {
    //       name: "Germany",
    //       coords: [51.17661451970939, 10.97947735117339],
    //     },
    //     {
    //       name: "Brazil",
    //       coords: [-7.596735421549542, -54.781694323779185],
    //     },
    //     {
    //       name: "Russia",
    //       coords: [62.318222797104276, 89.81564777631716],
    //     },
    //     {
    //       name: "China",
    //       coords: [22.320178999475512, 114.17161225541399],
    //       style: {
    //         fill: "#E91E63",
    //       },
    //     },
    //   ],
    //   markerStyle: {
    //     initial: {
    //       fill: "#e91e63",
    //     },
    //     hover: {
    //       fill: "E91E63",
    //     },
    //     selected: {
    //       fill: "E91E63",
    //     },
    //   },
    // });
  },
};
</script>
