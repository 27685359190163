/*
=========================================================
* Vuetify Material Dashboard 2 PRO - v3.0.0
=========================================================

* Product Page:  https://www.creative-tim.com/product/vuetify-material-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import HeaderAdmin from './components/Layout/HeaderAdmin.vue'
import FooterAdmin from './components/Layout/FooterAdmin.vue'
import Header from './components/Layout/Header.vue'
import Footer from './components/Layout/Footer.vue'
const axios = require('axios').default;
import VueSignature from "vue-signature-pad";
Vue.use(VueSignature);
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: 'AIzaSyD57RMFAiyqYcAD5mJ6uTJT9xqxQxF6_fo', // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
  version: '', // Optional
});
import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueMeta from 'vue-meta';

Vue.use(VueMeta);
Vue.use(VueToast);
import { VueMaskDirective } from 'v-mask'
import vueVimeoPlayer from 'vue-vimeo-player'
Vue.use(vueVimeoPlayer)
Vue.directive('mask', VueMaskDirective);
Vue.component('vue-header-admin', HeaderAdmin)
Vue.component('vue-footer-admin', FooterAdmin)
Vue.component('vue-header', Header)
Vue.component('vue-footer', Footer)
Vue.config.productionTip = false;
const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
      },
});
http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
window.axios = http;
window.Vue = Vue;
// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

// plugin setup
Vue.use(DashboardPlugin);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
