<template>
    <v-app id="inspire" style="background: radial-gradient(#40404b, #111118) rgba(34, 34, 40, .94);">

    <div style=" justify-content: space-around" class="mt-3 mb-1">
        <v-row class="pl-5 pr-5" style="background:#F7F7F7;">
        <v-col cols="12" align="center" justify="center" >
                <img v-if="!company_info && loadingLogo" @click="$router.push('/')" :height="this.$vuetify.breakpoint.xs ? 80 : 200" class="image-logo2" src="@/assets/img/logo.png">
                <img v-if="company_info && company_info.logo && loadingLogo" @click="$router.push('/')" :height="this.$vuetify.breakpoint.xs ? 80 : 300" class="image-logo2" :src="base_url_img+company_info.logo">
        </v-col>
        </v-row>

    <v-row style=" justify-content: space-around;margin-bottom:100px;">
        

        <v-col sm="8" md="4">

        <v-card class="card-shadow border-radius-xl mt-5">
             <div style="display: flex; justify-content: space-around; margin-top:100px;" >
                <h5 class="font-weight-bold text-h2 text-typo mb-0 mt-5">
              Set Password
            </h5>
            </div>
            <v-card-text class="mb-0">
            <v-form  class="mt-8">
                <v-text-field
                    label="Password"
                    style="margin-bottom:-18px;"
                    ref="email"
                    :type="show1 ? 'text' : 'password'"
                    class="font-size-input input-style"
                    prepend-inner-icon="fa-key"
                    :append-icon="show1 ? 'fa-eye' : 'fa-eye-slash'"
                    v-model="password"
                    @click:append="show1 = !show1"
                ></v-text-field>

                <v-text-field
                id="password"
                label="Confirm Password"
                name="password"
                style="margin-bottom:-18px;"
                prepend-inner-icon="fa-key"
                v-model="conf_password"
                ref="password"
                :type="show2 ? 'text' : 'password'"
                :append-icon="show2 ? 'fa-eye' : 'fa-eye-slash'"
                @click:append="show2 = !show2"
                class="font-size-input input-style"
                />
                    
            </v-form>
            </v-card-text>
            <v-card-actions class="justify-center ">
                <v-btn   large  color="#4CAF50"
class="
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          align-center
                        " v-on:click="checkForm" >Save</v-btn>
            </v-card-actions>
           
        </v-card>
        </v-col>
    </v-row>
    </div>
</v-app>
</template>

<script>
// import image from "./assets/profile.png"
import Utility from "@/components/common/functions.js";
export default {
props: {
    source: String,
},

data () {
return {
    email : '',
    password : '',
    showPassword : false,
    password : '',
    conf_password:'',
    dialog: false,
    url: '',
    loadingLogo : false,
    show1 : false,
    show2 : false,
    pageFlag: 'login',
    company_info : '',
    base_url_img : process.env.VUE_APP_WASBI_URL,
    isDark :true,
    buttonFlag   : false,

}
},
created () {
        this.url = window.location.origin;//this.$route.params.url ? this.$route.params.url : '';
        if(this.url)
            this.getCompanyInfo(this.url)
        else
            this.loadingLogo = true;
    },
beforeCreate: function () {
    if (localStorage.getItem('token')) {
        if(localStorage.getItem('is_admin'))
            this.$router.push('/admin/dashboard')
        else
            this.$router.push('/dashboard')
    }
},
methods: {
gotohome: function () {
    let self = this;
    self.$router.push({ name: "Home" });
},
getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            this.company_info = response.data.company;
            // this.loadingFlag = true;
            this.loadingLogo = true;
          })

},
forgotPass(){
    this.buttonFlag =true;
    this.isDark = false;
    axios({
          method: "POST",
          url: "/api/auth/forgot-password",
          data: {
            email: this.email,
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Forgot Password Mail sent', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.isDark = true;
                this.pageFlag = 'login';
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
},
gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},
/**
 * checkForm
 * login the user with valid email/pass
 */
checkForm: function(e) {
      var loginData = {};
      var header = {};
      let flag = 1;
      if (!this.password) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }
      if (!this.conf_password && flag == 1) {
        Vue.$toast.error('Confirm Password is required', {
            position: 'top-right'
        });
        this.$refs['conf_password'].focus()
        flag = 0;
      }
      if (this.conf_password != this.password && flag == 1) {
        Vue.$toast.error('Confirm Password didn\'t match', {
            position: 'top-right'
        });
        this.$refs['conf_password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        var email = this.email;
        axios({
          method: "POST",
           
          url: "/api/auth/passwordreset/"+this.$route.params.token+"/"+this.$route.params.email,
          data: {
            confirm_password: this.password,
            password: this.password,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.flag == 1) {
              let result = data.data;
              localStorage.setItem("token", data.token.token);
              localStorage.setItem("user_id", result.user_id);
              localStorage.setItem("email", result.email);
              localStorage.setItem("name", result.first_name+' '+result.last_name);
              localStorage.setItem("is_admin",result.is_admin);
              if(result.roles.length != 0)
                localStorage.setItem("role", JSON.stringify(result.roles[0]));
              else
                localStorage.setItem("role", JSON.stringify({name : 'client'}));
              
              Vue.$toast.success('Password has been reset', {
            position: 'top-right'
            });
            this.$router.push("/dashboard");
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }
    },

},

}
</script>

<style scoped>
   html {
  overflow: hidden !important;
}

</style>
<style>
.separator {
    display: flex;
    align-items: center;
    text-align: center;
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgba(0,0,0,.6);
}
.separator::before {
    margin-right: .25em;
}
.separator::after {
    margin-left: .25em;
}
</style>
