<template>
  <v-card class="card-shadow border-radius-xl text-center" elevation="8">
    <v-card-text >
    <h1 class=" text-h6 text-typo mt-5" >Complete the form to view Inventory.</h1>
    <div class="mt-5">
   
            <v-text-field
                label="First Name"
                color="black"
                outlined
                rounded
                v-model="partner.first_name"
                dense
                class="font-size-input input-style py-0 "
            ></v-text-field>
            <v-text-field
                label="Last Name"
                outlined
                rounded
                v-model="partner.last_name"
                dense
                style="margin-top: -15px;"
                class="font-size-input input-style py-0"
            ></v-text-field>
            <v-text-field
                label="Email"
                outlined
                rounded
                v-model="partner.email"
                dense
                style="margin-top: -15px;"
                class="font-size-input input-style py-0"
            ></v-text-field>
            <v-text-field
                label="Phone"
                outlined
                rounded
                v-model="partner.phone"
                dense
                style="margin-top: -15px;"
                class="font-size-input input-style py-0"
            ></v-text-field>
            <v-checkbox   style="margin-top: -15px;">
                <template v-slot:label>
                    <div>
                        I agree to
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <a
                            target="_blank"
                            href="/terms-and-conditions"
                            @click.stop
                            style="color:#FFCC00FF ;"
                            v-on="on"
                        >
                        terms & conditions
                        </a>
                        </template>
                        Opens in new window
                    </v-tooltip>
                    provided by the company. By providing my phone number, I agree to receive text messages from the business.
                    </div>
                </template>
                </v-checkbox>
   </div>
        <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                rounded
                outlined
                :loading="isDisable"
                class="
                font-weight-bold
                text-uppercase
                btn-primary
                
                py-2
                px-6
                me-2
                mb-2
                w-100
                "
                :color="color"
                small
                @click="checkForm()"
                >Submit</v-btn
            >
    </v-card-text>
  </v-card>
</template>

<script>
export default {
props: ["color","ref_id","id"],
  data: () => ({
    partner: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
    },
    isDisable: false

  }),
  methods: {
    checkForm(){
            let self = this;
            let flag = 1;            
            if (!this.partner.first_name) {
                Vue.$toast.error('First Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.last_name && flag == 1) {
                Vue.$toast.error('Last Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.email && this.partner.is_email == false && flag == 1) {
                Vue.$toast.error('Email is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            

           
            let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (reg.test(this.partner.email) == false && this.partner.is_email == false  && flag == 1) {
                Vue.$toast.error('Invalid email format', {
                    position: 'top-right'
                });
                flag = 0;
            }

            
            this.partner.user_name = this.partner.email;
            if (flag == 1) {
                this.loadingSaveButton = true;
                let is_verified = localStorage.getItem('email_verified') ? localStorage.getItem('email_verified') : 0
                this.partner.company_id = this.company ? this.company.id : '';
                this.isDark = false;
                this.isDisable = true;
                const data = new FormData();
                for (var key in self.partner) {
                  data.append(key,self.partner[key]);
                }    
                data.append('ref_id', this.ref_id);            
                axios({
                method: "POST",
                url: "/api/auth/register-card-reffer",
                data:data,
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                    if(response.data.flag && response.data.flag == 3){
                          Vue.$toast.error('Client exist with same email address!', {
                            position: 'top-right'
                            });
                            this.isDark = true;
                            this.isDisable = false;
                            this.loadingSaveButton = false;
                      }else{
                        let result = response.data.data;
                        this.partner = {
                            first_name: '',
                            last_name: '',
                            email: '',
                            phone: '',
                        };
                        localStorage.setItem("view-inventory-flag",1);
                        this.$router.push('/view-inventory-details/'+this.id)
                      }   
                })
                .catch(err => {
                  console.log(err);
                    // console.log(JSON.parse(err.response.data));
                    this.isDark = true;
                    this.isDisable = false;
                    this.loadingSaveButton = false;
                    if(err.response.data.flag){
                        if(err.response.data.flag == 2){
                            Vue.$toast.error('Company exist with same email address!', {
                            position: 'top-right'
                            });
                        }
                        if(err.response.data.flag == 3){
                          Vue.$toast.error('Client exist with same email address!', {
                            position: 'top-right'
                            });
                        }
                    }else{
                      let data = self.isJson(err.response.data) ? JSON.parse(err.response.data) : (err.response.data);
                      if(data.email){
                          Vue.$toast.error(data.email[0], {
                          position: 'top-right'
                          });
                      }else if(data.password){
                          Vue.$toast.error(data.password[0], {
                          position: 'top-right'
                          });
                      }
                      else if(data.user_name){
                          Vue.$toast.error(data.user_name[0], {
                          position: 'top-right'
                          });
                      }
                      else{
                          Vue.$toast.error(data, {
                          position: 'top-right'
                          });
                      }
                  }
                    self.onCaptchaExpired()

                });

            }
        
    }
  }
}
</script>

<style>

</style>