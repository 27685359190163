<template>

    <v-app id="inspire">
      <vue-header-admin v-if="role.name != 'client'"></vue-header-admin>
      <v-main>
    <v-row  justify="center"  style="margin-top:150px;margin-bottom: 50px;">
        <v-col cols="12"  sm="12" md="12" lg="8" xs="10">
          <div style="display: flex; justify-content: space-around; " >
          </div>
          <v-row  justify="center" class="mt-10" >
            <v-col cols="12"  sm="4" md="4" lg="4" xs="12" v-for="price in pricing" :key="price.id">

            <v-card
            class="shadow-lg border-radius-xl"
            :class="'bg-gradient-default' "
          >
            <div class="px-6 pb-4 text-center">
              <v-btn
                elevation="0"
                small
                :ripple="false"
                height="21"
                class="
                  border-radius-xl
                  font-weight-bolder
                  px-8
                  py-3
                  badge-font-size
                  ms-auto
                  mt-n6
                "
                :class="'text-white'"
                :color="'#e91e63'"
                >{{price.name}}</v-btn
              >
              <h1
                class="text-h1 font-weight-bold mt-4"
                :class=" 'text-white'"
              >
                ${{ price.price
                }}
              </h1>

              <v-list
              v-if="price.items"
                class="py-6 text-lg-start text-center"
                color="transparent"
              >
                <v-list-item
                v-for="item in JSON.parse(price.items)"
                  class="px-2"
                >
                  <v-icon
                    size="20"
                    class="material-icons-round"
                    :class="'text-white' "
                  >
                    done
                  </v-icon>

                  <v-list-item-content class="text-left">
                    <v-list-item-title
                      
                      class="ps-0 font-weight-light ms-4"
                      :class="'text-white'"
                    >
                      {{item.item}}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  btn-primary
                  py-3
                  text-xs
                  px-6
                  ls-0
                  w-100
                  border-radius-md
                "
                :class="`bg-gradient-success`"
              >
                Buy Credit
                <v-icon size="12">fas fa-arrow-right ms-2</v-icon>
              </v-btn>
            </div>
          </v-card>





            <!-- <v-card class="border-radius-xl card-shadow">
            <div class="px-6 py-6 text-center">
              <h1 style="color:#FF5353;" class="text-gradient text-primary font-weight-bolder text-h1">
                <span>${{price.price}}</span>
              </h1>
              <h6 class="mb-0 text-h4 font-weight text-typo">
                {{price.credit}} Credit
              </h6>
              <p class="opacity-8 mb-0 text-sm text-typo mt-2"><v-btn
                elevation="0"
                @click="openPaymentDialog(price.price,price.id)"
                :ripple="false"
                height="43"
                color="#4CAF50"
                class="
                  font-weight-normal
                  text-capitalize
                  ms-auto
                  btn-primary
                  py-3
                  px-6
                  ms-3
                "
                >Buy Credit</v-btn
              ></p>
            </div>
          </v-card> -->
               
            </v-col>
          </v-row>
          <v-row class="mt-10" justify="center" id="card-div" >
            <v-col cols="12"  sm="12" md="12" lg="8" xs="10" v-show="selectedProduct">
           <div style="display: flex; justify-content: space-around; " >
            <h2 class="text-h1 font-weight-bolder text-typo">Billing Information</h2>
            
           </div>
            </v-col>
            <v-col v-show="selectedProduct" cols="12"  sm="12" md="12" lg="8" xs="10"><v-divider></v-divider></v-col>
            <v-col v-show="selectedProduct" cols="12"  sm="12" md="12" lg="8" xs="8"><p class="mt-3"><strong style="font-size:30px;">Total: </strong> <span class="total-price">{{formatPrice(Math.round(selectedPrice))}}<v-chip
                  v-if="chip3"
                  class="ma-2"
                  close
                  color="green"
                  outlined
                  @click:close="chip3 = false, removeCoupon()"
                >
                  {{coupon_name}} - Applied
                </v-chip></span><span></span></p>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="8" xs="12" v-show="selectedProduct">
              <v-text-field
                                v-model="coupon_model"
                                label="Apply Coupon code"
                                color="#009688"
                                height="53px"
                                placeholder="Apply Coupon code"
                                class="mt-5"
                                filled
                                ref="dob"
                                solo
                                rounded
                            >
                            <template v-slot:append-outer>
                              <v-btn
                                  rounded
                                  color="button-default"
                                  style="height: 53px;
                                        min-width: 64px;
                                        padding: 0 40px;
                                        margin-top: -11px;" 
                                        @click="getCouponCodeAPi()"
                                  dark
                                >
                                  APPLY
                                </v-btn>
                            </template>
                            
              </v-text-field>
           </v-col>
           
           <v-col style="justify-content: space-around!important;display: flex!important;" v-show="selectedProduct" cols="12"  sm="12" md="12" lg="8" xs="8">
          <cardView :selectedPrice="selectedPrice" :selectedProduct="selectedProduct" :settings="settings" v-show="selectedProduct" :coupon="coupon.id" ></cardView>
          </v-col>
          </v-row>
        </v-col>
    </v-row>
    <vue-footer-admin></vue-footer-admin>
    </v-main>
</v-app>
</template>

<script>
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
import moment from 'moment';
// import image from "./assets/profile.png"
import Utility from "@/components/common/functions.js";
import cardView from "@/components/CardView";
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
export default {
props: {
    source: String,
},
components :{cardView},
data () {
return {
    email : '',
    password : '',
    showPassword : false,
    dialog: false,
    name: '',
    radios: 1,
    monthRadio: 1,
    is_downgrade: '',
    basic_price : 0,
    coupon_name: '',
    coupon_model: '',
    coupon : {
      value : 0,
      type :'',
      id : ''
    },
    standard_price: 0,
    premium_price:0,
    pricing: [],
    changecardFlag:false,
    newCard: {
      number: '',
      exp: '',
      cvv: ''
    },
    subscription_flag: false,
    valid: true,
    start_date : moment(moment(), "YYYY-MM-DD").add(5, 'days').format('MMMM DD'),
    city: '',
    country:'',
    upgrade_data : {
      plan : '',
      amount: '',
      type: ''
    },
    settings :{
            day1: 30,
            day2: 21,
            day2_price: 1500,
            pricing :{
                family_plan_price_monthly : "",
                family_plan_price_yearly : "",
                individual_plan_price_monthly : "",
                individual_plan_price_yearly : "",
                individual_plus_plan_price_monthly : "",
                individual_plus_plan_price_yearly : "",
            }

        },
    existing_plan: localStorage.getItem('plan') ? localStorage.getItem('plan') : '',
    recurring_type: localStorage.getItem('recurring_type') ?  localStorage.getItem('recurring_type') : '',
    card_last: localStorage.getItem('card_last') ? localStorage.getItem('card_last') : '',
    plan:'',
    swithInd:'on',
    checkbox:'',
    DarkCallbackRequestDisable: false,
            DarkCallbackRequest: true,
            callbackModal:false,
            callback : {
            name : '',
            phone: '',
            message:''
            
            },
    upgradeButtonDisable: false,
    upgradeButtonDark: true,
    // pricing: {
    //   family: {
    //     monthly: 27.95,
    //     yearly: 15.00
    //   },
    //   individual: {
    //     monthly: 14.95,
    //     yearly: 8.33
    //   },
    //   individual_plus: {
    //     monthly: 229.95,
    //     yearly: 8.33
    //   },
    // },
    swithFamily:'on',
    swithPrem:'on',
    zip:'',
    is_verified:1,
    address:'',
    interval: {},
    selectedPrice:0,
    selectedProduct: '',
    coupon_model : '',
    value: 0,
    role : JSON.parse(localStorage.getItem('role')),
    chip3:false,
    coupon_amount : 0,
    state:'',
    flag: 'email',
    rules: [
        value => !!value || 'Required.',
      ],
      

}
},
beforeCreate: function () {
},
created() {

},
watch: {
    radios (val) {
      if (val == 1) {
        if(this.swithPrem == 'on')
          this.premium_price = Number(this.settings.pricing.individual_plus_plan_price_yearly) 
        else
          this.premium_price = Number(this.settings.pricing.individual_plus_plan_price_monthly) 
      }else{
        this.premium_price = Number(this.premium_price) + Number(this.settings.day2_price)
        
      }
    }
  },
mounted(){
    this.getSettings();
    this.getCouponAPi();
    

},
methods: {
  moment: function () {
    return moment();
  },
  openPaymentDialog(price, id){
    this.selectedProduct = id;
    this.selectedPrice = price;
    this.$vuetify.goTo('#card-div')
  },
  applyCoupon(){
      if(this.coupon.type =='Percentage'){
        this.coupon_amount = (this.selectedPrice* (this.coupon.value/100))
        this.selectedPrice =  this.selectedPrice  - this.coupon_amount
      }else{
        this.coupon_amount = this.coupon.value
        this.selectedPrice =  this.selectedPrice  - this.coupon_amount
      }
  },
  removeCoupon(){

      this.selectedPrice =  Number(this.selectedPrice)  + Number(this.coupon_amount)
      this.coupon = {
          value : 0,
            type :'',
            id : ''
      }
    //this.chip3 = fa;
  },
  loadPrice(id){
    if(id == 'basic'){
      this.basic_price = this.swithFamily == 'on' ? this.settings.pricing.family_plan_price_yearly  : this.pricing.basic.month1.total
    }else if(id == 'standard'){
      this.standard_price = this.swithInd == 'on' ? this.settings.pricing.individual_plan_price_yearly : this.pricing.standard.month1.total
    }else if(id =='premium'){
      this.premium_price = this.swithPrem == 'on' ? this.settings.pricing.individual_plus_plan_price_yearly : this.pricing.premium.month1.total
      if(this.radios == '2'){
        this.premium_price = Number(this.premium_price) + Number(this.settings.day2_price)
      }
    }
  },
  formatPrice(value) {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        });
        return formatter.format(value);
    },
  callbackRequest(){
    let self = this;
            let flag = 1;
            if (!this.callback.name) {
                Vue.$toast.error('Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.callback.phone && flag == 1) {
                Vue.$toast.error('Phone is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            
            if (!this.callback.message && flag == 1) {
                Vue.$toast.error('Message is required', {
                    position: 'top-right'
                });
                flag = 0;
            }

            if (flag == 1) {
                this.DarkCallbackRequest = false;
                this.DarkCallbackRequestDisable = true;
                axios({
                method: "POST",
                 
                url: "/api/contact-us",
                data:{email : self.callback.phone, name : self.callback.name, message : self.callback.message},
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    Vue.$toast.success('Thank you for contacting us! We will be in touch shortly', {
                    position: 'top-right'
                    });
                    this.callback.name = '';
                    this.callback.phone= '';
                    this.callback.message = '';
                    this.DarkCallbackRequest = true;
                    this.DarkCallbackRequestDisable = false;
                    this.callbackModal = false;
                })
                .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });

                });

            }
  },
  gotolink(){
     window.open("https://et128.infusionsoft.app/app/orderForms/8af3e2e6-e1df-4961-bfc4-6baab234428a?inf_contact_key=1b4b1c6cb624cc3319971b58584723282a5ca6532929dafb2b557e851d458580&cookieUUID=e0c7e59b-e2eb-4375-9417-8f06affd8cc8");
  },
getSettings() {
    let flag = 1;
    axios({
          method: "POST",
           
          url: "/api/get-company-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            console.log(data);
            if(data){
              localStorage.setItem("settings",JSON.stringify(data.settings))
              this.settings = data.settings;
              
            }


         })
          .catch(err => {
            

           });
     },
  gotocontact(){
      this.$router.push('/contact-us')
    },

gotohome: function () {
    let self = this;
    self.$router.push({ name: "Home" });
},
gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},
getCouponAPi(){
  axios({
          method: "POST",
           
          url: "/api/get-all-promo-codes",
          data : {type : 'Client'},
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            console.log(data);
            if(data){
             this.pricing = data;
            }


         })
          .catch(err => {
            

           });
},
getCouponCodeAPi(){
  axios({
          method: "POST",
           
          url: "/api/get-coupon-id",
          data: {coupon : this.coupon_model},
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(response.data.status == 1){
              this.coupon.value = data.value
              this.coupon.type = data.type
              this.coupon_name = this.coupon_model
              this.coupon.id = data.id;
              this.applyCoupon();
              this.chip3 = true;
              Vue.$toast.success('Coupon code applied!', {
                    position: 'top-right'
                    });
              this.coupon_model = '';
            }else if(response.data.status == 2){
                 Vue.$toast.error('Invalid coupon applied!', {
                    position: 'top-right'
                    });
            }else if(response.data.status == 3){
                 Vue.$toast.error('Coupon has been used!', {
                    position: 'top-right'
                    });
            }


         })
          .catch(err => {
            

           });

},
gotodashboard(){
    this.$router.push('/dashboard');
},
gotologin(){
    this.$router.push('/');
},
/**
 * checkForm
 * login the user with valid email/pass
 */


},


}
</script>
<style>
.cards {
  display: flex;
}
.cards label {
  cursor: pointer;
}
.cards label input {
  display: none;
}
.cards label .text {
  color: #515151;
  font-family: "roboto", arial, sans-serif;
  display: flex;
  flex-direction: column;
  padding: 30px 50px 30px 50px!important;
  justify-content: center;
  /* width: 100px;
  height: 150px; */
  margin: 0 16px;
  border: 1px solid #ffffff;
  background: #ffffff;
  text-align: center;
}
.price{
  font-weight: 200;
font-size: 3.4em;
color: #8CC526;
line-height: 1em;
}
/* .cards label.isActive {
  font-size: 30px;
} */
.cards label:hover .text{
  border-color: #EE0808;
  box-shadow: 0 0 8px #ccc;
}
.cards label input:checked + .text {
  color: #EE0808;
  border-color: #EE0808;
}
</style>
<style scoped>
   html {
  overflow: hidden !important;
}

</style>
<style>
.separator {
    display: flex;
    align-items: center;
    text-align: center;
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgba(0,0,0,.6);
}
.separator::before {
    margin-right: .25em;
}
.separator::after {
    margin-left: .25em;
}
.card-header-1{
  text-align: left;
  font-size: 34px !important;
  text-transform: uppercase;
  width: 100%;
  color: white;
  font-weight: 900!important;
}
.price{
  display: block;
  font-size: 34px;
  color: #505050;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 20px;
}
.small-text {
    font-size: 16px !important;
    font-weight: 300;
}

.plan-title{
  font-family: Lato,Arial,sans-serif;
  font-weight: 700;
  line-height: 1;
  color: #303030;
}
.total-price{
  color: #D13242;
  margin-bottom: 5px;
  font-size: 30px;
}
.product-span{
  color: #D13242;
  font-family: poppins-extralight,poppins,sans-serif;
  font-weight: bold;
  font-size: calc(20px + (24 - 20) * ((100vw - var(--minViewportSize) * 1px) / (var(--maxViewportSize) - var(--minViewportSize))));
  text-align: left;
  letter-spacing: 0.02em;
  line-height: 3;
  min-height: 0vw;
}
</style>
