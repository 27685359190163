<template>
<v-app id="inspire" style="background:#F9F9F9;">

<vue-header-admin></vue-header-admin>
  <v-main >
    <v-container style="margin-bottom: 72px;" fluid class="px-6 py-6">
      
       <v-tabs
       class="mt-3"
       color="#4CAF50"
                  left
                >
                  <v-tab>Conversation Settings</v-tab>
                  <v-tab-item
                  style="background-color: transparent!important;"
                    :key="0"
                  >
        <v-row
            align="center"
            justify="center"
            class=" mb-5"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl mt-6" id="basic">
                <v-card-text style="margin-bottom: -30px;" >
                  <v-row>
                       
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <!-- <h1 class="text-center mt-5 mb-5">Company Settings</h1> -->
                    <!-- <h3 class=".font-weight-black  header-primary-city" style="font-size: 40px;color:181818;margin-top:35px" >Settings</h3> -->
                    <h5 class="text-h4 font-weight-bold text-typo">Twilio Settings</h5>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                    
                        <v-text-field
                            color="#e91e63"
                            label="Account SID"
                            ref="name"
                        v-model="settings.sid"
                            class="font-size-input input-style"
                          >
                          </v-text-field>
                    </v-col>
                       <v-col
                      cols="12"
                      md="6"
                    >
                    
                    <v-text-field
                            color="#e91e63"
                            label="Auth Token"
                            ref="name"
                        v-model="settings.token"
                            class="font-size-input input-style"
                          >
                          </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                    
                    <v-text-field
                            color="#e91e63"
                            label="Phone Number"
                            ref="name"
                            v-mask="'(###) ###-####'"
                        v-model="settings.phone"
                            class="font-size-input input-style"
                          >
                          </v-text-field>
                    </v-col>
                    <v-col
                      cols="6"
                      md="6"
                    >
                    <p>
                  Twilio Webhook URL: <strong>{{ api_url }}api/receive-twilio-sms</strong></p>
                  <p>You can set webhook url into your twilio account to receive sms reply.</p>
                  </v-col>
                    <!-- <v-col
                      cols="12"
                      md="12"
                    >

                    <h5 class="text-h4 font-weight-bold text-typo">Email SMTP Settings</h5>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                    
                        <v-text-field
                            color="#e91e63"
                            label="SMTP HOST"
                            ref="name"
                        v-model="settings.smtp_host"
                            class="font-size-input input-style"
                          >
                          </v-text-field>
                    </v-col>
                       <v-col
                      cols="12"
                      md="6"
                    >
                    
                    <v-text-field
                            color="#e91e63"
                            label="SMTP PORT"
                            ref="name"
                        v-model="settings.smtp_port"
                            class="font-size-input input-style"
                          >
                          </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                    <v-text-field
                            color="#e91e63"
                            label="SMTP USER NAME"
                            ref="name"
                        v-model="settings.smtp_user_name"
                            class="font-size-input input-style"
                          >
                          </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                    <v-text-field
                            color="#e91e63"
                            label="SMTP PASSWORD"
                            ref="name"
                        v-model="settings.smtp_password"
                            class="font-size-input input-style"
                          >
                          </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                    <v-text-field
                            color="#e91e63"
                            label="SMTP ENCRYPTION"
                            ref="name"
                        v-model="settings.smtp_encryption"
                            class="font-size-input input-style"
                          >
                          </v-text-field>
                    </v-col> -->
                    
                      <!-- <v-col
                      cols="12"
                      md="6"
                    >
                    <v-text-field
                            color="#e91e63"
                            label="From Email"
                            ref="name"
                        v-model="settings.from_email"
                            class="font-size-input input-style"
                          >
                          </v-text-field>
                    
                    </v-col> -->
                      <!-- <v-col
                      cols="12"
                      md="6"
                    >
                    <p style="text-align:left;">Payment Mode</p>
                    <v-switch
                        v-model="settings.test_mode"
                        label="Test"
                        style="margin-bottom: 30px;"
                        color="green"
                        value="1"
                        hide-details
                      >
                      <template #prepend>
                        <v-label>Live</v-label>
                      </template>
                      </v-switch>
                    
                    </v-col> -->
                   
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-center ">
                  <v-row>
                    <v-col>
                      <v-btn
                        color="#4CAF50"
                        large
                        @click="saveSettings"
                        class="
                        float-right
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          ms-auto
                        "
                        
                      >
                        Save
                      </v-btn>
                      </v-col>
                    </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
      </v-tab-item>
       </v-tabs>

    </v-container>
    <vue-footer-admin></vue-footer-admin>
    </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import HtmlEditor from "../HtmlEditor.vue";
import Sortable from 'sortablejs';
export default {
components: {
      VueTelInput,HtmlEditor
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        api_url: process.env.VUE_APP_API_URL,
        id: '',
        name:'',
        editedItemBasic: {
          item: '',
        },
        
        status:0,
        settings :{
            sid: '',
            token : '',
            phone: '',
            smtp_host : "",
            smtp_port : '',
            smtp_user_name:'',
            smtp_password : '',
            smtp_encryption : 'TLS'

        },
        tab: null,
        showDialog: false,
      icon: '',
      searchIcon: '',
      selectedIcon: 'mdi-check-outline',
     

      }
    },
    directives: {
    sortableDataTable: {
      bind (el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit('sorted', event)
          }
        }
        Sortable.create(el.getElementsByTagName('tbody')[0], options)
      }
    }
  },
    methods: {
      
    getSettings() {
    let flag = 1;
    var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
      axios({
          method: "get",
           
          url: "/api/get-conversation-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.id = data.id;
              this.settings = JSON.parse(data.settings);
            }


         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
     productEditOpen(item){
      this.product = item
      this.product.items = this.product.items ? Array.isArray(this.product.items) ? this.product.items :JSON.parse(this.product.items) : []
      this.dialogAssign=true
     },
     previewImageBG: function(event) {
      var input = event.target;
      console.log(input);
      if (input.files) {
        this.settings.backgroung_logo = input.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.previewbg = e.target.result;
        }
        this.image=input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
     getAllCompanies(){
            let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
            axios
            .get("/api/get-all-company", {headers: headers})
            .then(response => {
                this.companies = response.data.data;
                this.companies.push({name: 'None',id:'None'})
            })
        },
    deleteItem(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-promo/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getProducts();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemLocation(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-location/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getL();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemConfirmBasic (plan) {
        this.product.items.splice(this.editedIndexBasic, 1)
        this.closeDeleteBasic()
      },
        deleteItemFA(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-focus-area/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getFA();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemLetter(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .post("/api/delete-letter/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getLetters();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemLink(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-link/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getLinks();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemBasic (flag,item) {
        this.editedIndexBasic = this.product.items.indexOf(item)
        this.editedItemBasic = Object.assign({}, item)
        this.dialogDeleteBasic = true
      },
        closeBasic () {
        this.dialog_basic = false
        this.$nextTick(() => {
          this.editedItemBasic = Object.assign({}, {item : ''})
          this.editedIndexBasic = -1
        })
      },
        getAddressData: function (addressData, placeResultData, id) {
            this.location.name = addressData.name;
        },
        editItemBasic (flag,item) {
        this.editedIndexBasic = this.product.items.indexOf(item)
        this.editedItemBasic = Object.assign({}, item)
        this.dialog_basic = true
      },
        saveBasic (item) {
        if (this.editedIndexBasic > -1) {
          Object.assign(this.product.items[this.editedIndexBasic], this.editedItemBasic)
        } else {
          this.product.items.push(this.editedItemBasic)
        }
        this.closeBasic()
      },
        deleteItemCoupon(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-coupon/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getCoupons();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
    getPayments(){
            this.loading = true;
           
            axios({
          method: "GET",
           
          url: "/api/get-all-payment-gateway",
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
            this.payment_gateway_options = response.data.data
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
    addPromo(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-promo",
          data: this.product
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Product added successfully', {
                      position: 'top-right'
                      });
                      this.dialogAssign = false;
                      this.loadingAssign = false;
                      this.getProducts();
                      this.product.price = '';
                      this.product.credit = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      addFocusArea(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-focus-area",
          data: this.fa
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Focus Area added successfully', {
                      position: 'top-right'
                      });
                      this.dialogAddFA = false;
                      this.loadingAssign = false;
                      this.getFA();
                      this.fa.name = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      addLocation(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-location",
          data: this.location
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Location added successfully', {
                      position: 'top-right'
                      });
                      this.dialogAddL = false;
                      this.loadingAssign = false;
                      this.getL();
                      this.location.name = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      addCoupon(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-coupon-codes",
          data: this.promo
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Coupon added successfully', {
                      position: 'top-right'
                      });
                      this.dialogCoupon = false;
                      this.loadingAssign = false;
                      this.getCoupons();
                      this.promo = {
                            name : '',
                            type : 'Percentage',
                            value : '',
                            count : 1
                          };
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active Coupon code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      editCoupon(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-coupon/"+this.promo.id,
          data: this.promo
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Coupon edited successfully', {
                      position: 'top-right'
                      });
                      this.dialogCoupon = false;
                      this.loadingAssign = false;
                      this.getCoupons();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active Coupon code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      editPromo(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-promo/"+this.product.id,
          data: this.product
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Product edited successfully', {
                      position: 'top-right'
                      });
                      this.dialogAssign = false;
                      this.loadingAssign = false;
                      this.getProducts();
                      this.product.price = '';
                      this.product.credit = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      editFocusArea(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-focus-area/"+this.fa.id,
          data: this.fa
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Focus Area edited successfully', {
                      position: 'top-right'
                      });
                      this.dialogAddFA = false;
                      this.loadingAssign = false;
                      this.getFA();
                      this.fa.name = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      uploadLogoBG(){
      const data = new FormData();
      let url =process.env.VUE_APP_WASBI_URL
        data.append('file_logo', this.settings.backgroung_logo);
        axios({
          method: "POST",
          url: "/api/upload-logo-background-admin",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          Vue.$toast.success("Logo uploaded", {
              position: 'top-right'
              });
              console.log();
              this.settings.backgroung_logo = response.data.data
              this.saveSettings()
              localStorage.setItem('admin_bg',url+response.data.data)
          this.loadingUpload = false;

          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      
    },
      editLocation(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-location/"+this.location.id,
          data: this.location
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Location edited successfully', {
                      position: 'top-right'
                      });
                      this.dialogAddL = false;
                      this.loadingAssign = false;
                      this.getL();
                      this.location.name = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      addLink(){
        if(this.$refs.form.validate()){
        this.loadingCustomLink = true;
        let req = this.link;
        req.icon = this.selectedIcon;
        req.list_account = JSON.stringify(req.list_account)
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-cusom-link",
          data: req
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Custom Link added successfully', {
                      position: 'top-right'
                      });
                      this.dialogCustomLink = false;
                      this.loadingCustomLink = false;
                      this.getLinks();
                      this.link.title = '';
                      this.link.url= '';
                      this.link.list_account= [];
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
              this.loadingCustomLink = false;
              })
        }
          
            
      },
      editLink(){
        if(this.$refs.form.validate()){
        this.loadingCustomLink = true;
        let req = this.link;
        req.icon = this.selectedIcon;
        req.list_account = JSON.stringify(req.list_account)
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-custom-link",
          data: req
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Custom Link edited successfully', {
                      position: 'top-right'
                      });
                      this.dialogCustomLink = false;
                      this.loadingCustomLink = false;
                      this.getLinks();
                      this.link.title = '';
                      this.link.url= '';
                      this.link.list_account= [];
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
              this.loadingCustomLink = false;
              })
        }
          
            
      },
    getProducts(){
      this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-promo-codes",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.users = data.data;
                this.pageCount = data.total_page
                this.totalCount = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getFA(){
      this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options_fa.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : ''
            }
            axios({
          method: "POST",
           
          url: "/api/get-focus-area",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.focus_area = data.data;
                this.pageCountFA = data.total_page
                this.totalCountFA = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getL(){
      this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options_l.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : ''
            }
            axios({
          method: "POST",
           
          url: "/api/get-location",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.locations = data.data;
                this.pageCountL = data.total_page
                this.totalCountL = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getCoupons(){
      this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options_coupons.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options_coupons.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options_coupons.sortBy,
              sortDesc : this.datatable_options_coupons.sortDesc && this.datatable_options_coupons.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-coupon-codes",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.coupons = data.data;
                this.pageCountCoupons = data.total_page
                this.totalCountCoupons = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getLinks(){
      this.loading = true;
            this.offset = (this.pageLink - 1) * 10
            this.selected_project = name;
            let req = {
              limit : -1,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-custom-links",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.links = data.data;
                this.pageCountLink = data.total_page
                this.totalCountLink = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getLetters(){
      this.loading = true;
            var offset = (this.page_letter - 1) * 10
            let req = {
              limit : this.limit_letter,
              offset : offset,
              sort : this.datatable_options_letter.sortBy,
              sortDesc : this.datatable_options_letter.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search_letter
            }
            axios({
          method: "POST",
           
          url: "/api/get-all-letter",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.letters = data.data;
                this.pageCountLetter = data.total_page
                this.totalCountLetter = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    closeDeleteBasic () {
        this.dialogDeleteBasic = false
        this.$nextTick(() => {
          this.editedItemBasic = Object.assign({}, {item:''})
          this.editedIndexBasic = -1
        })
      },
    saveSettings: function(e) {

        axios({
          method: "POST",
           
          url: "/api/save-conversation-settings",
          data: {
            id: this.id,
            settings: this.settings
          }
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Settings saved', {
                    position: 'top-right'
                  })
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
    },
    saveIcon: function(icon) {
      this.icon = icon;
      this.selectedIcon = "mdi-" + icon;
      this.showDialog = false;
    }

 },
 
watch: {
  dialogDeleteBasic (val) {
        val || this.closeDeleteBasic()
      },
    searchIcon: function(oldSearchIcon, newSearchIcon) {
    let filteredIcons = [];
    if (this.searchIcon.length === 0) {
      filteredIcons = this.allIcons;
    } else {
      filteredIcons = this.allIcons.filter(i => i.includes(this.searchIcon));
    }
    this.allIcons = filteredIcons;
  },
  datatable_options: {
                handler () {
                    console.log(this.datatable_options);
                this.getProducts()
                },
                deep: true,
            },
  datatable_options_coupons : {
    handler () {
                this.getCoupons()
                },
                deep: true,
  },
  datatable_options_fa : {
    handler () {
                this.getFA()
                },
                deep: true,
  },
  datatable_options_l : {
    handler () {
                this.getL()
                },
                deep: true,
  }
},
created() {
  if(!localStorage.getItem('token')){
    this.$router.push('/login')
    }else{
      console.log('created called.');
      this.getSettings();
      // this.getProducts();
      this.getLinks()
      this.getAllCompanies();
      this.getLetters();
      this.getPayments();
    }
      
    },

}
</script>

<style>
.theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent !important;
}

</style>